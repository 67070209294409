import { closeAllModals, openModal } from "@mantine/modals";
import { showNotification } from "@mantine/notifications";
import { useLocation, Navigate, Outlet, useNavigate } from "react-router-dom";
import { Login } from "../features/auth/Login";
import { HasAccess } from "./PermissionComponent";

const RequireAuth = ({ allowedRoles, allowedPermissions, background }: any) => {
  const location = useLocation();
  const navigate = useNavigate();

  const auth = JSON.parse(localStorage.getItem("auth") || "null");

  if (!auth?.isAuthenticated) {
    navigate("/sign-in", {
      state: { from: location, authed: true },
    });
  }

  return (
    // !auth?.isAuthenticated ? (
    //   <Navigate
    //     to={`/sign-in`}
    //     state={{ background: background || "/", from: location, authed: true }}
    //   />
    // ) :
    <HasAccess
      roles={allowedRoles}
      permissions={allowedPermissions}
      onAuthFailed={() => {
        navigate("/");
        showNotification({
          id: "Bummer",
          color: "red",
          title: "Bummer",
          message: "No access to " + location.pathname,
        });
      }}
    >
      <Outlet />
    </HasAccess>
  );
  //  :
  //  <Navigate to={`/`} state={{ background: background || '/', from: location, authed: true }} />
  // <Outlet />
};

export default RequireAuth;
