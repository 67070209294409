import React from "react";
import {
  createStyles,
  Text,
  Container,
  ActionIcon,
  Group,
  Image,
  Anchor,
  Center,
} from "@mantine/core";
import {
  Whatsapp,
  Youtube,
  Instagram,
  Call,
  Sms,
  Location,
} from "iconsax-react";
import FitaboLogo from "../../assets/logo/fitabo.svg";
import Fitabo from "../../assets/logo/logo.png";
import { Link } from "react-router-dom";

const data = [
  // {
  //   title: "About",
  //   links: [
  //     {
  //       label: "The Team",
  //       link: "#",
  //     },
  //     {
  //       label: "Fitabo Vision",
  //       link: "#",
  //     },
  //     {
  //       label: "Our Story",
  //       link: "#",
  //     },
  //     {
  //       label: "Reviews",
  //       link: "#",
  //     },
  //   ],
  // },
  {
    title: "Terms of Use",
    links: [
      {
        label: "Terms and Conditions",
        link: "/policies",
      },
      // {
      //   label: "Services Policy",
      //   link: "/policies",
      // },
      // {
      //   label: "Privacy Policy and Data Protection",
      //   link: "/policies",
      // },
      // {
      //   label: "Refund and Cancelation",
      //   link: "/policies",
      // },
      {
        label: "Fitabo SOP's",
        link: "/sop",
      },
      // {
      //   label: "FAQ's",
      //   link: "/faqs",
      // },
    ],
  },
  {
    title: "On fitabo",
    links: [
      {
        label: "Blog",
        link: "/blog",
      },
      {
        label: "Shop",
        link: "/shop",
      },
      {
        label: "Events",
        link: "/events",
      },
    ],
  },
  // {
  //   title: "Let's Connect",
  //   links: [
  //     {
  //       label: "Newsletter",
  //       link: "#",
  //     },
  //     {
  //       label: "Instagram",
  //       link: "#",
  //     },
  //     {
  //       label: "Facebook",
  //       link: "#",
  //     },
  //     {
  //       label: "Twitter",
  //       link: "#",
  //     },
  //   ],
  // },
];

const useStyles = createStyles((theme) => ({
  footer: {
    paddingTop: theme.spacing.xl * 2,
    paddingBottom: theme.spacing.xl * 2,
    backgroundColor: "rgba(244, 129, 109,0.1)",
    borderTop: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[2]
    }`,
  },

  logo: {
    maxWidth: 240,

    [theme.fn.smallerThan("sm")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  },

  description: {
    marginTop: 5,

    [theme.fn.smallerThan("sm")]: {
      marginTop: theme.spacing.xs,
      textAlign: "center",
    },
  },

  inner: {
    display: "flex",
    justifyContent: "space-between",

    [theme.fn.smallerThan("sm")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },

  groups: {
    display: "flex",
    flexWrap: "wrap",

    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },

  wrapper: {
    minWidth: 140,
    maxWidth: 200,
  },

  link: {
    display: "block",
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[1]
        : theme.colors.gray[6],
    fontSize: theme.fontSizes.sm,
    paddingTop: 3,
    paddingBottom: 3,

    "&:hover": {
      textDecoration: "underline",
    },
  },

  title: {
    fontSize: theme.fontSizes.lg,
    fontWeight: 700,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    marginBottom: theme.spacing.xs / 2,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
  },

  afterFooter: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: theme.spacing.xl,
    paddingTop: theme.spacing.xl,
    paddingBottom: theme.spacing.xl,
    borderTop: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[2]
    }`,

    [theme.fn.smallerThan("sm")]: {
      flexDirection: "column",
    },
  },

  social: {
    [theme.fn.smallerThan("sm")]: {
      marginTop: theme.spacing.xs,
    },
  },
}));

export function FooterSection() {
  const { classes } = useStyles();

  const groups = data.map((group) => {
    const links = group.links.map((link, index) => (
      <Text
        key={index}
        className={classes.link}
        component={Link}
        to={link.link}
        // onClick={(event) => event.preventDefault()}
      >
        {link.label}
      </Text>
    ));

    return (
      <div className={classes.wrapper} key={group.title}>
        <Text className={classes.title}>{group.title}</Text>
        {links}
      </div>
    );
  });

  return (
    <footer className={classes.footer}>
      <Container size="xl" className={classes.inner}>
        <div className={classes.logo}>
          <Center>
            <Image src={Fitabo} width={42} alt="wearefitabo" />
          </Center>
          <Image src={FitaboLogo} width={160} alt="wearefitabo" />
          <Text
            size="xs"
            align="center"
            color="dimmed"
            className={classes.description}
          >
            thinkfit.
          </Text>
        </div>
        <div className={classes.groups}>
          <div style={{ width: 280 }}>
            <Text className={classes.title}>{"Contacts"}</Text>

            <Group spacing={0} className={classes.social} noWrap>
              <Call className="mainTextColor" />
              <a
                aria-label="phone number"
                href="tel:+255675666711"
                style={{ padding: "6px" }}
              >
                <Text size="sm">+255-675-666-711</Text>
              </a>
            </Group>
            <Group spacing={0} className={classes.social} noWrap>
              <Sms size={24} className="mainTextColor" />
              <a
                aria-label="wearefitabo@gmail.com"
                href="mailto:wearefitabo@gmail.com"
                style={{ padding: "6px" }}
              >
                <Text size="sm">wearefitabo@gmail.com</Text>
              </a>
            </Group>
            <Group
              pb="xs"
              spacing="xs"
              color="dimmed"
              className={(classes.social, "mainTextColor")}
              noWrap
              align="start"
            >
              <Location />

              <Text size="sm" color={"dimmed"}>
                Victoria Noble Center,
                <br />
                4th floor, Office No. 406,
                <br />
                New Bagamoyo Rd,
                <br /> Dar es salaam, Tanzania
              </Text>
            </Group>
          </div>
          {groups}
        </div>
      </Container>
      <Container size="xl" className={classes.afterFooter}>
        <Text tt="capitalize" color="dimmed" size="sm">
          © {new Date().getFullYear()} Fitabo Ltd. All rights reserved.
        </Text>

        <Group spacing={0} className={classes.social} position="right" noWrap>
          <ActionIcon size="lg" aria-label="Whatsapp">
            <a
              target={"_blank"}
              aria-label="Whatsapp"
              href="https://wa.me/message/BEN7D2VXDLNPA1"
            >
              <Whatsapp size={18} className={"mainTextColor"} />
            </a>
          </ActionIcon>
          <ActionIcon size="lg" aria-label="Youtube">
            <a
              target={"_blank"}
              aria-label="Youtube"
              href="https://youtube.com/channel/UCTSj2RYx6A58W4DZnIn6y0Q"
            >
              <Youtube size={18} className={"mainTextColor"} />
            </a>
          </ActionIcon>
          <ActionIcon size="lg" aria-label="Instagram">
            <a
              target={"_blank"}
              aria-label="Instagram"
              href="https://instagram.com/wearefitabo?igshid=YmMyMTA2M2Y="
            >
              <Instagram size={18} className={"mainTextColor"} />
            </a>
          </ActionIcon>
        </Group>
      </Container>
    </footer>
  );
}
