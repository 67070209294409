import { showNotification, updateNotification } from "@mantine/notifications";
import { InfoCircle } from "iconsax-react";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FullPageLoader from "../../components/Loaders/FullPageLoader";
import { useVerifyEmailMutation } from "./authSlice";

const EmailVerification = () => {
  const { token } = useParams();
  const [emailVerification] = useVerifyEmailMutation();
  const navigate = useNavigate();

  const verifyEmail = async () => {
    showNotification({
      id: "verifyingEmail",
      loading: true,
      title: "Verify Email",
      message: "VerifyingEmail...",
      autoClose: false,
      disallowClose: true,
    });

    await emailVerification({ token })
      .unwrap()
      .then((res) => {
        updateNotification({
          id: "verifyingEmail",
          color: "teal",
          icon: <InfoCircle />,
          title: "Successful",
          message: "Email verified successfuly",
          autoClose: 2000,
        });

        navigate("/profile");
      })
      .catch((err) => {
        updateNotification({
          id: "verifyingEmail",
          color: "red",
          title: "Failed to verify email",
          icon: <InfoCircle />,
          message: err?.data?.info || "",
          autoClose: 2000,
        });

        navigate("/profile");
      });
  };

  useEffect(() => {
    verifyEmail();
  }, []);
  // verifyEmail();

  return <FullPageLoader text="" />;
};

export default EmailVerification;
