import { serviceProvidersSlice } from "./../ServiceProvider/serviceProvidersSlice";
import { appSlice } from "../../app/appSlice";

export const usersSlice = appSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: () => "/users",
      providesTags: ["Users"],
    }),
    addUser: builder.mutation({
      query: (body) => ({
        url: "/users",
        method: "POST",
        body: body,
      }),

      async onQueryStarted({ body }, { dispatch, queryFulfilled }) {
        try {
          const { data: addedUser } = await queryFulfilled;
          const patchResult = dispatch(
            usersSlice.util.updateQueryData("getUsers", "", (draft: any) => {
              draft.push(addedUser?.user);
            })
          );
        } catch (err) {}
      },
    }),
    updateUser: builder.mutation({
      query: ({ _id, body }) => ({
        url: `/users/${_id}`,
        method: "PATCH",
        body: body,
      }),
      async onQueryStarted(
        { _id, updating, body },
        { dispatch, queryFulfilled }
      ) {
        try {
          const { data: response } = await queryFulfilled;
          if (updating === "provider") {
            dispatch(
              usersSlice.util.updateQueryData("getUsers", "", (draft: any) => {
                const user = draft.find((user: any) => user._id === _id);
                Object.assign(user, response?.owner);
                console.log("worldyy");
              })
            );

            dispatch(
              serviceProvidersSlice.util.updateQueryData(
                "getAllProviders",
                "",
                (draft: any) => {
                  const provider = draft.find(
                    (provider: any) => provider?.owner?._id === _id
                  );

                  console.log("world");

                  Object.assign(provider, response);
                }
              )
            );

            dispatch(
              serviceProvidersSlice.util.updateQueryData(
                "getProvidersProfile",
                "",
                (draft: any) => {
                  Object.assign(draft, response);
                }
              )
            );
          } else {
            dispatch(
              usersSlice.util.updateQueryData("getUsers", "", (draft: any) => {
                const user = draft.find((user: any) => user._id === _id);
                Object.assign(user, response);
              })
            );
          }
        } catch (err) {}
      },
    }),

    updateUserRole: builder.mutation({
      query: ({ _id, body }) => ({
        url: `/users/${_id}/roles`,
        method: "PATCH",
        body: body,
      }),
      async onQueryStarted({ _id, body }, { dispatch, queryFulfilled }) {
        try {
          const { data: response } = await queryFulfilled;
          dispatch(
            usersSlice.util.updateQueryData("getUsers", "", (draft: any) => {
              const user = draft.find(
                (user: any) => user._id === response?.user?._id
              );
              Object.assign(user, response?.user);
            })
          );
        } catch (err) {}
      },
    }),
    suspendUser: builder.mutation({
      query: ({ _id, body }) => ({
        url: `/users/${_id}`,
        method: "DELETE",
        body: body,
      }),
      async onQueryStarted({ _id, body }, { dispatch, queryFulfilled }) {
        try {
          const { data: response } = await queryFulfilled;
          dispatch(
            usersSlice.util.updateQueryData("getUsers", "", (draft: any) => {
              const user = draft.find(
                (user: any) => user._id === response?.user?._id
              );
              Object.assign(user, body);
            })
          );
        } catch (err) {}
      },
    }),
  }),
});

export const {
  useGetUsersQuery,
  useAddUserMutation,
  useUpdateUserMutation,
  useUpdateUserRoleMutation,
  useSuspendUserMutation,
} = usersSlice;
