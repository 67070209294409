import {Center, Loader, LoadingOverlay} from "@mantine/core";

export default function FullLoader({text}: { text?: string }) {

    return (
        // <div style={{width:'100vw !important', height:'100vh !important', display:'flex', justifyContent:'center', alignItems:'center', position:'absolute'}}>
        <LoadingOverlay visible={false} />   
        // </div>
    )
}
