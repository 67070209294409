import React, { Suspense, useEffect, useState } from "react";
import "./app.css";
import {
  MantineProvider,
  ColorSchemeProvider,
  ColorScheme,
  TypographyStylesProvider,
  Image,
  Text,
  Skeleton,
  UnstyledButton,
  Loader,
  LoadingOverlay,
} from "@mantine/core";
import { useHotkeys, useLocalStorage } from "@mantine/hooks";
import CacheBuster from "react-cache-buster";
import {
  hideNotification,
  NotificationsProvider,
  showNotification,
} from "@mantine/notifications";
import FullPageLoader from "../components/Loaders/FullPageLoader";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import RequireAuth from "../components/RequireAuth";
import { Register } from "../features/auth/Register";
import Logout from "../features/auth/Logout";
import "react-lazy-load-image-component/src/effects/blur.css";
import version from "../../package.json";
import Layout from "../components/Layout";
import { ModalsProvider } from "@mantine/modals";
import { PermifyProvider } from "../components/PermissionComponent";
import { ServiceDetails } from "../features/Services/ServiceDetails";
import { SpotlightAction, SpotlightProvider } from "@mantine/spotlight";
import { IconSearch } from "@tabler/icons";
import { Events } from "../features/Events";
import { io } from "socket.io-client";
import { getMessaging, onMessage } from "firebase/messaging";
import { initializeApp } from "firebase/app";
import { ErrorPage } from "../components/ErrorPage";
import { useGetCategoriesQuery } from "../features/Categories/categoriesSlice";
import { Login } from "../features/auth/Login";
import { useSearchQuery } from "./searchSlice";
import ReactGA from "react-ga";
import logo from "../assets/logo/logo.png";
import ForgotPassword from "../features/auth/ForgotPassword";
import EmailVerification from "../features/auth/EmailVerification";
import { ResetPassword } from "../features/auth/ResetPassword";
import OAuthRedirect from "../features/auth/OAuthRedirect";

const TRACKING_ID = "UA-253326214-1"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

const firebaseConfig = {
  apiKey: "AIzaSyD8Mrs-F-xiO0O3xWGJbIlqjgv0U7Gkun4",
  authDomain: "wearefitabo-76de2.firebaseapp.com",
  projectId: "wearefitabo-76de2",
  storageBucket: "wearefitabo-76de2.appspot.com",
  messagingSenderId: "657921022646",
  appId: "1:657921022646:web:64130af87cd12c9e7ce417",
  measurementId: "G-5MXMWSZZ8K",
};
initializeApp(firebaseConfig);

const authObject = JSON.parse(localStorage.getItem("auth") || "null");

export const socket = io(
  process.env.NODE_ENV === "development"
    ? "localhost:2500"
    : "www.wearefitabo.com/dev/api",
  {
    auth: { _id: authObject?.id },
    path: process.env.NODE_ENV === "development" ? "" : "/dev/api/socket.io",
  }
);

const Landing = React.lazy(async () => ({
  default: (await import("../features/Landing")).Landing,
}));

const ProviderDetails = React.lazy(async () => ({
  default: (await import("../features/ServiceProvider/ProviderDetails"))
    .ProviderDetails,
}));
const BookServiceProvider = React.lazy(async () => ({
  default: (
    await import("../features/Bookings/BookServiceProvider/BookServiceProvider")
  ).BookServiceProvider,
}));
const ContactUs = React.lazy(async () => ({
  default: (await import("../features/ContactUs")).ContactUs,
}));
const About = React.lazy(async () => ({
  default: (await import("../features/About")).About,
}));
const ClientBookings = React.lazy(async () => ({
  default: (await import("../features/Bookings/ClientBookings")).ClientBookings,
}));
const ProviderBookings = React.lazy(async () => ({
  default: (await import("../features/Bookings/ProviderBookings"))
    .ProviderBookings,
}));
const UserProfile = React.lazy(async () => ({
  default: (await import("../features/Profile/UserProfile")).UserProfile,
}));
const ServiceProviderProfile = React.lazy(async () => ({
  default: (
    await import(
      "../features/Profile/ServiceProviderProfile/ServiceProviderProfile"
    )
  ).ServiceProviderProfile,
}));
// const Login = React.lazy(async () => ({
//   default: (await import("../features/auth/Login")).Login,
// }));
const Policies = React.lazy(async () => ({
  default: (await import("../features/PoliciesSopsFaqs/Policies")).Policies,
}));
const Sop = React.lazy(async () => ({
  default: (await import("../features/PoliciesSopsFaqs/Sop")).Sop,
}));
const Faq = React.lazy(async () => ({
  default: (await import("../features/PoliciesSopsFaqs/Faq")).Faq,
}));
const Shop = React.lazy(async () => ({
  default: (await import("../features/Shop/Shop")).Shop,
}));
const Admin = React.lazy(async () => ({
  default: (await import("../pages/Admin")).Admin,
}));
const TheBlog = React.lazy(async () => ({
  default: (await import("../features/Blogs/TheBlog")).TheBlog,
}));
const BlogsDetails = React.lazy(async () => ({
  default: (await import("../features/Blogs/BlogsDetails")).BlogsDetails,
}));

const Portfolio = React.lazy(async () => ({
  default: (await import("../features/ServiceProvider/Portfolio")).Portfolio,
}));

const ROLES = {
  User: "client",
  Provider: "serviceProvider",
  Trainer: "trainer",
  Admin: "admin",
};

let actions: SpotlightAction[] = [];

function App() {
  const isProduction = process.env.NODE_ENV === "production";
  return (
    <CacheBuster
      currentVersion={version.version}
      isEnabled={isProduction} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      loadingComponent={<FullPageLoader text="" />} //If not pass, nothing appears at the time of new version check.
    >
      <MainApp />
    </CacheBuster>
  );
}

function MainApp() {
  const [colorScheme, setColorScheme] = useLocalStorage<ColorScheme>({
    key: "mantine-color-scheme",
    defaultValue: "light",
    // defaultValue: preferredColorScheme,
    getInitialValueInEffect: true,
  });
  const location = useLocation();
  const state: any = location.state;
  const background = location.state && state.background;
  const navigate = useNavigate();
  const toggleColorScheme = (value?: ColorScheme) =>
    setColorScheme(value || (colorScheme === "dark" ? "light" : "dark"));

  useHotkeys([["mod+J", () => toggleColorScheme()]]);
  let auth: any = { isAuthenticated: false };
  if (localStorage.getItem("auth")) {
    auth = JSON.parse(localStorage.getItem("auth") || "null");
  } else {
    localStorage.setItem("auth", JSON.stringify({ isAuthenticated: false }));
  }

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [window.location.pathname]);

  if ("serviceWorker" in navigator) {
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
      // console.log(payload);
      let link = payload?.data?.link;

      showNotification({
        id: "push-notification",
        title: (
          <UnstyledButton
            onClick={() => {
              navigate(link?.split("wearefitabo.com")[1] || location.pathname);
              hideNotification("push-notification");
            }}
          >
            {payload?.notification?.title || "Notification"}{" "}
          </UnstyledButton>
        ),
        message: (
          <Text
            color="dimmed"
            onClick={() => {
              navigate(link?.split("wearefitabo.com")[1] || location.pathname);
              hideNotification("push-notification");
            }}
          >
            {payload?.notification?.body || "You have new notification"}
          </Text>
        ),
        autoClose: 5000,
        color: "gray",
        icon: (
          <Image
            // src="https://fitabo-bucket.s3.amazonaws.com/fitaboLogo.webp"
            src={logo}
            placeholder={<Skeleton height={24} width={24} />}
            style={{ background: "white" }}
          />
        ),
      });
    });
  }

  const [searchQuery, setSearchQuery] = useState<string>("");
  // const debouncedSearchQuery = useDebounce(searchQuery, 500);
  const {
    data: searchResult,
    isSuccess: searched,
    // isErrorSearch,
  } = useSearchQuery({ query: searchQuery || "" });
  // } = useSearchQuery(debouncedSearchQuery, { skip: debouncedSearchQuery == "" });

  actions =
    searchResult?.map((item: any, i: any) => {
      return {
        title: item?.name,
        description: item?.expectations?.replace(/<[^>]+>/g, "").slice(0, 72),
        onTrigger: () => navigate(`/providers/${item?._id}`),
        icon: (
          <Image
            src={item?.displayImage?.sm}
            width={72}
            height={72}
            fit={"cover"}
            placeholder={<Skeleton width={72} height={72} />}
          />
        ),
      };
    }) || [];

  return (
    <PermifyProvider>
      <ColorSchemeProvider
        colorScheme={colorScheme}
        toggleColorScheme={toggleColorScheme}
      >
        <MantineProvider
          theme={{
            fontFamily: "Poppins",
            fontFamilyMonospace: "Poppins",
            headings: { fontFamily: "Poppins" },
            // headings: { fontFamily: "Open Sans, sans-serif, Noto Serif JP" },
            colors: {
              brand: [
                "#FFE6E2",
                "#FFCDC5",
                "#FEB4A7",
                "#FEA799",
                "#FE9B8A",
                "#FE8E7C",
                "#F4816D",
                "#FE8E7C",
                "#FE826D",
                "#F4816D",
              ],
            },
            primaryColor: "brand",
            colorScheme,
          }}
          withGlobalStyles
          withNormalizeCSS
        >
          <NotificationsProvider position="top-center">
            <ModalsProvider>
              <TypographyStylesProvider>
                <SpotlightProvider
                  actions={actions}
                  searchIcon={<IconSearch size={18} />}
                  searchPlaceholder="Search..."
                  shortcut="mod + shift + 1"
                  nothingFoundMessage="Nothing found..."
                  transitionDuration={300}
                  transition="slide-down"
                  onQueryChange={(value) => {
                    setSearchQuery(value || "");
                  }}
                  filter={(query, actions) => actions}
                >
                  <div className="App">
                    <Suspense
                      fallback={
                        <LoadingOverlay
                          loaderProps={{
                            size: "lg",
                          }}
                          overlayBlur={2}
                          overlayColor="transparent"
                          visible
                        />
                      }
                    >
                      <Routes location={background || location}>
                        <Route path="/" element={<Layout />}>
                          <Route
                            path="/"
                            element={
                              <Landing
                                categoryData={{
                                  _id: undefined,
                                  name: undefined,
                                  description:
                                    "Find and book fitness, beauty, sports, and wellness sessions at any time, anywhere. Get personalized sessions, save time, build consistency. more than 1,000 categories with 500+ services",
                                }}
                              />
                            }
                          />
                          <Route
                            path="/:category"
                            element={
                              <Landing
                                categoryData={{
                                  _id: undefined,
                                  name: undefined,
                                  description:
                                    "Find and book fitness, beauty, sports, and wellness sessions at any time, anywhere. Get personalized sessions, save time, build consistency. more than 1,000 categories with 500+ services",
                                }}
                              />
                            }
                          />
                          {/* {categories?.map((category: any) => (
                            <Route
                              key={category?._id}
                              path={`/${category?.name?.replace(/ /g, "%20")}`}
                              element={<Landing categoryData={category} />}
                            />
                          ))} */}

                          <Route path="/shop" element={<Shop />} />
                          <Route path="/events" element={<Events />} />
                          <Route path="/OAuthRedirecting" element={<OAuthRedirect />} />
                          <Route path="about" element={<About />} />
                          <Route path="contacts" element={<ContactUs />} />
                          <Route
                            path="services/:serviceId"
                            element={<ServiceDetails />}
                          />
                          <Route
                            path="providers/:providerId"
                            element={<ProviderDetails />}
                          />
                          <Route
                            path="providers/:providerId/portfolio"
                            element={<Portfolio />}
                          />
                          <Route
                            path="forgotpassword/"
                            element={<ForgotPassword />}
                          />
                          <Route
                            path="resetPassword/:token"
                            element={<ResetPassword />}
                          />
                          <Route path="blog" element={<TheBlog />} />
                          <Route
                            path="blog/:blogsId"
                            element={<BlogsDetails />}
                          />

                          <Route path="sign-out" element={<Logout />} />
                          <Route
                            path="emailVerification/:token"
                            element={<EmailVerification />}
                          />
                          <Route path="policies" element={<Policies />} />
                          <Route path="sop" element={<Sop />} />
                          <Route path="faqs" element={<Faq />} />
                          <Route
                            path="sign-in"
                            element={
                              <Login
                                onCloseLogin={() => {}}
                                goTo="/"
                                openLogin={false}
                              />
                            }
                          />
                          <Route path="sign-up" element={<Register />} />

                          {/* we want to protect these routes */}
                          <Route
                            element={
                              <RequireAuth
                                background={location}
                                allowedRoles={[ROLES.User]}
                              />
                            }
                          ></Route>

                          <Route
                            element={
                              <RequireAuth
                                allowedRoles={[ROLES.Admin, ROLES.Trainer]}
                              />
                            }
                          >
                            <Route
                              path="myservices"
                              element={<ProviderDetails />}
                            />
                          </Route>

                          <Route
                            element={
                              <RequireAuth
                                allowedRoles={[
                                  ROLES.User,
                                  ROLES.Admin,
                                  ROLES.Trainer,
                                  ROLES.Provider,
                                ]}
                              />
                            }
                          >
                            <Route
                              path="profile"
                              element={
                                <UserProfile />
                              }
                              
                            />

                            <Route
                              path="book/:providerId"
                              element={<BookServiceProvider />}
                            />
                            <Route
                              path="bookings"
                              element={
                                auth?.accountType === "serviceProvider" ? (
                                  <ProviderBookings />
                                ) : (
                                  <ClientBookings />
                                )
                              }
                            />
                            <Route
                              path="bookings/:bookingId"
                              element={
                                auth?.accountType === "serviceProvider" ? (
                                  <ProviderBookings />
                                ) : (
                                  <ClientBookings />
                                )
                              }
                            />
                          </Route>

                          {/* catch all */}
                          <Route
                            path="*"
                            element={<ErrorPage errorCode={404} />}
                          />
                        </Route>
                        <Route
                          element={<RequireAuth allowedRoles={[ROLES.Admin]} />}
                        >
                          <Route path="/admin/*" element={<Admin />} />
                        </Route>
                        <Route
                          path="*"
                          element={<ErrorPage errorCode={404} />}
                        />
                      </Routes>
                    </Suspense>
                    {/* )} */}
                  </div>
                </SpotlightProvider>
              </TypographyStylesProvider>
            </ModalsProvider>
          </NotificationsProvider>
        </MantineProvider>
      </ColorSchemeProvider>
    </PermifyProvider>
  );
}

export default App;
