import React, { useState } from "react";
import {
  createStyles,
  Text,
  UnstyledButton,
  Center,
  Footer,
  Burger,
} from "@mantine/core";
import { SearchNormal1, Profile, Calendar, Logout } from "iconsax-react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { openModal, closeAllModals } from "@mantine/modals";
import { Login } from "../../features/auth/Login";

const useStyles = createStyles((theme) => ({
  footer: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "end",
    fontFamily: "Poppins",
    margin: "0 !important",
    width: "100%!important",
    position: "fixed",
    zIndex: 1,
    bottom: 0,
    paddingTop: theme.spacing.lg,
    paddingRight: theme.spacing.lg,
    borderTop: "1px solid rgba(0,0,0,0.1)",
    paddingBottom: theme.spacing.sm,
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[6]
        : theme.colors.gray[0],
    [theme.fn.largerThan("sm")]: {
      display: "none",
    },
    transition: "all 500ms ease",
  },
  tab: {
    color:
      theme.colorScheme === "dark"
        ? theme.colors.gray[0]
        : theme.colors.dark[6],
  },
  active: {
    color: "#F4816D !important",
  },
}));

export function BottomNav({
  opened,
  openSideBar,
  closeSideBar,
}: {
  opened: boolean;
  openSideBar?: (values: any) => void;
  closeSideBar: () => void;
}) {
  const { classes } = useStyles();
  const location = useLocation();
  const state: any = location?.state;
  const navigate = useNavigate();
  const auth = JSON.parse(localStorage.getItem("auth") || "null");
  const [openLogin, setOpenLogin] = useState(false);

  // const [offset, setOffset] = useState(0);
  // let prv = 0;

  // useEffect(() => {
  //   const onScroll = () => {
  //     const pageOffset = window.pageYOffset;
  //     let tempOffset = pageOffset - prv;
  //     if (tempOffset >= 100) {
  //       setOffset(-100);
  //       prv = pageOffset - 100;
  //       tempOffset = 0;
  //     } else {
  //       setOffset(0);
  //       // setOffset((offset + tempOffset) > 0 ? 0 : offset - tempOffset);
  //       prv = pageOffset - 100;
  //       tempOffset = 0;
  //     }
  //   };
  //   // clean up code
  //   window.removeEventListener("scroll", onScroll);
  //   window.addEventListener("scroll", onScroll, { passive: true });
  //   return () => window.removeEventListener("scroll", onScroll);
  // }, [onscroll]);

  return (
    <Footer height={82} className={classes.footer}>
      <UnstyledButton
        mx="xs"
        className={location.pathname === "/" ? classes.active : classes.tab}
        onClick={() => navigate("/")}
      >
        <Center>
          <SearchNormal1 size={24} />
        </Center>
        <Text size="sm" pt={4}>
          Explore
        </Text>
      </UnstyledButton>
      <UnstyledButton
        mx="xs"
        className={
          location.pathname === "/bookings" || state?.to === "/bookings"
            ? classes.active
            : classes.tab
        }
        onClick={() => {
          auth?.isAuthenticated ? navigate("/bookings") : setOpenLogin(true);
        }}
      >
        <Center>
          <Calendar size={24} />
        </Center>
        <Text size="sm" pt={4}>
          Bookings{" "}
        </Text>
      </UnstyledButton>
      {auth?.isAuthenticated ? (
        <>
          {auth?.role?.name === "admin" ? (
            <UnstyledButton
              mx="xs"
              className={!opened ? classes.active : classes.tab}
              onClick={() => {
                openSideBar && openSideBar("k");
              }}
            >
              <Center>
                {/* <MenuBoard size={24} variant="Bold" /> */}
                <Burger
                  pb={0}
                  size={20}
                  opened={!opened}
                  color={!opened ? "#F4816D" : ""}
                />
              </Center>
              <Text size="sm">More</Text>
            </UnstyledButton>
          ) : (
            <>
              <UnstyledButton
                mx="xs"
                className={
                  location.pathname === "/profile"
                    ? classes.active
                    : classes.tab
                }
                onClick={() => {
                  navigate("/profile");
                }}
              >
                <Center>
                  <Profile size={24} />
                </Center>
                <Text size="sm" pt={4}>
                  Profile
                </Text>
              </UnstyledButton>

              <UnstyledButton
                mx="xs"
                className={
                  location.pathname === "/sign-out"
                    ? classes.active
                    : classes.tab
                }
                onClick={() => {
                  navigate("/sign-out");
                }}
              >
                <Center>
                  <Logout size={24} />
                </Center>
                <Text size="sm" pt={4}>
                  Logout
                </Text>
              </UnstyledButton>
            </>
          )}
        </>
      ) : (
        <>
          <UnstyledButton
            mx="xs"
            className={
              location.pathname === "/sign-in" && state?.to !== "/bookings"
                ? classes.active
                : classes.tab
            }
            onClick={() => {
              setOpenLogin(true);
              // openModal({
              //   centered: true,
              //   withCloseButton: false,
              //   padding: 0,
              //   radius: "md",
              //   children: (

              //   ),
              //   onClose: closeAllModals,
              // });
            }}
          >
            <Center>
              <Profile size={24} />
            </Center>
            <Text size="sm" pt={4}>
              Log in
            </Text>
          </UnstyledButton>
        </>
      )}
      <Login
        goTo={location.pathname}
        openLogin={openLogin}
        onCloseLogin={() => setOpenLogin(false)}
      />
    </Footer>
  );
}
