import React, { useState } from "react";
import {
  Card,
  Text,
  Group,
  createStyles,
  Button,
  Rating,
  AspectRatio,
  Skeleton,
  Paper,
  Badge,
} from "@mantine/core";
import { Link, useNavigate, useParams } from "react-router-dom";

import {
  LazyLoadComponent,
  LazyLoadImage,
  trackWindowScroll,
} from "react-lazy-load-image-component";
import PropTypes from "prop-types";
import { Login } from "../auth/Login";

const useStyles = createStyles((theme, _params, getRef) => {
  const image = getRef("image");

  return {
    card: {
      width: "100%",
      maxWidth: 340,
      margin: "auto",
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],

      [`&:hover .${image}`]: {
        transform: "scale(1.02)!important",
      },
    },

    imageWrapper: {
      overflow: "hidden",
    },
    image: {
      ref: image,

      objectFit: "cover",
      transition: "all 400ms ease !important",
    },

    category: {
      position: "absolute",
      background:
        theme.colorScheme === "dark" ? "#25262b" : "rgba(255,255,255,0.7)",
      borderTopLeftRadius: 8,
      backdropFilter: "blur(6px)",
      right: 0,
      padding: "4px 8px 2px 8px",
      marginTop: -31,
      pointerEvents: "none",
    },

    overlay: {
      position: "absolute",
      left: 0,
      right: 0,
      bottom: 0,
      backgroundImage:
        theme.colorScheme === "dark"
          ? "linear-gradient(180deg, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, .75) 90%)"
          : "linear-gradient(180deg, rgba(245, 245, 245, .5) 30%, rgba(245, 245, 245, .5) 90%)",
    },

    content: {
      height: "100%",
      position: "relative",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      zIndex: 1,
      color: theme.colorScheme === "dark" ? "#ffffff" : "",
    },

    title: {
      color: theme.white,
      marginBottom: 5,
    },

    bodyText: {
      color: theme.colors.dark[2],
      marginLeft: 7,
    },

    author: {
      color: theme.colors.dark[2],
    },
  };
});

function ServiceProviderCard(props: any) {
  const { data, scrollPosition } = props;
  const { classes, theme } = useStyles();
  let navigate = useNavigate();
  const { serviceId } = useParams();
  const auth = JSON.parse(localStorage.getItem("auth") || "null");
  const [openLogin, setOpenLogin] = useState(false);

  const servicePrice = data?.services?.find(
    (service: any) => service._id === serviceId
  )?.price;

  return (
    <div>
      <>
        <LazyLoadComponent
          scrollPosition={scrollPosition}
          placeholder={
            <Card mb="lg">
              <AspectRatio ratio={16 / 12} mb="xs">
                <Skeleton height={"100%"} radius={16} />
              </AspectRatio>
              <Skeleton height={12} width={"75%"} mb="xs" />
              <Group position="apart">
                <Skeleton height={12} width={"45%"} />
                <Skeleton height={12} width={"35%"} />
              </Group>
              <Skeleton height={42} />
            </Card>
          }
        >
          <Card shadow="sm" pt={0} p="lg" className={classes.card}>
            <Link
              to={"/providers/" + data?._id}
              state={data}
              style={{ textDecoration: "none" }}
            >
              {" "}
              <Card.Section className={classes.imageWrapper}>
                <AspectRatio ratio={16 / 12}>
                  <LazyLoadImage
                    className={classes.image}
                    effect={"blur"}
                    width={"100%"}
                    height={"100%"}
                    placeholderSrc={data?.displayImage?.sm}
                    scrollPosition={scrollPosition}
                    src={data?.displayImage?.lg}
                  />
                </AspectRatio>
                <Paper radius={0} className={classes.category}>
                  <Badge
                    size="lg"
                    variant="dot"
                    tt="capitalize"
                    style={{ border: "none" }}
                  >
                    {data?.category?.name}
                  </Badge>
                </Paper>
              </Card.Section>
              <Text
                pt={12}
                size={18}
                tt="capitalize"
                mt="md"
                weight={500}
                color={
                  theme.colorScheme === "dark" ? theme.colors.gray[0] : "dark"
                }
              >
                {data?.name}
              </Text>
              <Text
                lineClamp={1}
                size="sm"
                align="left"
                weight={400}
                color={"dimmed"}
              >
                {data?.expectations}
              </Text>
              <Group position="apart" align={"center"} mt="xs" noWrap>
                {(data?.price || servicePrice) && (
                  <Text weight={600} className="mainTextColor" size="lg">
                    {data?.price?.toLocaleString("en-US", {
                      style: "currency",
                      currency: "Tzs",
                    }) ||
                      servicePrice?.toLocaleString("en-US", {
                        style: "currency",
                        currency: "Tzs",
                      })}
                  </Text>
                )}
                <Rating
                  fractions={4}
                  value={data?.rating}
                  readOnly
                  color="primary"
                />
              </Group>
            </Link>

            <Button
              radius="md"
              mt="md"
              size={"md"}
              color={theme.primaryColor}
              style={{ width: "100%", flex: 1 }}
              onClick={() => {
                !auth?.isAuthenticated
                  ? setOpenLogin(true)
                  : navigate("/book/" + data?._id, {
                      state: { serviceId: undefined },
                    });
              }}
            >
              Book now
            </Button>
          </Card>
        </LazyLoadComponent>
      </>
      <Login
        goTo={"/book/" + data?._id}
        openLogin={openLogin}
        onCloseLogin={() => setOpenLogin(false)}
      />
    </div>
  );
}

ServiceProviderCard.prototypes = {
  data: PropTypes.any,
};

export default trackWindowScroll(ServiceProviderCard);
