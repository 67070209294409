function getServerUrl(): string {
  let clientUrl;

  if (window.location.hostname === "localhost") {
    clientUrl = "http://localhost:2500";
  }  else if (window.location.hostname === "www.bafredo.co.tz") {
    clientUrl = "https://www.wearefitabo.com/dev/api";
  } else {
    clientUrl = window.location.origin;
  }

  return clientUrl;
}

export default getServerUrl;
