import {
  Box,
  Button,
  Center,
  Image,
  Modal,
  Paper,
  Text,
  TextInput,
  Title,
  useMantineTheme,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useForgotPasswordMutation } from "./authSlice";
import { showNotification, updateNotification } from "@mantine/notifications";
import { InfoCircle } from "iconsax-react";
import { useMediaQuery } from "@mantine/hooks";
import Logo from "../../assets/logo/logo1.png";

const ForgotPassword = () => {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const state: any = location.state;
  const [forgotPassoword, forgotPasswordResponse] = useForgotPasswordMutation();

  const onForgotPassword = async (values: any) => {
    showNotification({
      id: "forgotPassword",
      loading: true,
      title: "Signing in",
      message: "Signing in, please wait...",
      autoClose: false,
      disallowClose: true,
    });

    await forgotPassoword(values)
      .unwrap()
      .then(async (res) => {
        updateNotification({
          id: "forgotPassword",
          color: "teal",
          title: "Successful",
          icon: <InfoCircle />,
          message: "Email sent successfull",
          autoClose: 1000,
          onClose: () => {
            navigate("/");
          },
        });
      })
      .catch((err) => {
        updateNotification({
          id: "forgotPassword",
          color: "red",
          title: "Failed to Send The Mail",
          icon: <InfoCircle />,
          message: err?.data?.info || "",
          autoClose: 2000,
        });
      });
  };

  const form = useForm({
    validateInputOnBlur: true,
    initialValues: {
      email: "",
    },
    validate: {
      email: (value) =>
        value.trim().length < 1
          ? "Email is required"
          : /^\S+@\S+$/.test(value)
          ? null
          : "Invalid Email",
    },
  });

  return (
    <Modal
      onClose={() => navigate("/")}
      opened={true}
      centered
      transition="rotate-left"
      transitionDuration={300}
      transitionTimingFunction="ease"
      overlayColor={
        theme.colorScheme === "dark"
          ? theme.colors.dark[9]
          : theme.colors.gray[2]
      }
      overlayOpacity={0.55}
      overlayBlur={3}
      fullScreen={isMobile}
      styles={() =>
        isMobile
          ? {
              root: {
                height: "80%",
                top: "100px",
              },
            }
          : {}
      }
    >
      <Center h={"100%"} mx={10} my={40}>
        <Paper
          p={24}
          radius="md"
          style={{
            width: "clamp(280px, 100%, 500px)",
            margin: "auto",
            background: "transparent",
          }}
        >
          <Image
            src={Logo}
            alt="wearefitabo.com"
            mx={"auto"}
            my={24}
            width={200}
          />
          <Title my={12} align="center" order={4}>
            {state?.head || "Forgot Password"}
          </Title>
          <Text align="center" color={"dimmed"}>
            {state?.subHead || "Please provide your email."}
          </Text>
          <Box pt={24}>
            <form
              style={{ width: "100%" }}
              onSubmit={(e) => {
                e.preventDefault();
                if (form.validate().hasErrors) return;
                onForgotPassword(form.values);
                console.log("form submited");
              }}
            >
              <TextInput
                label="Email"
                placeholder="example@mail.com"
                {...form.getInputProps("email")}
              />

              <Button fullWidth size="lg" radius={"md"} mt={16} type="submit">
                Submit
              </Button>
            </form>
          </Box>
        </Paper>
      </Center>
    </Modal>
  );
};

export default ForgotPassword;
