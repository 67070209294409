import { appSlice } from "../../app/appSlice";

export const categoriesSlice = appSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCategories: builder.query({
      query: () => "/categories",
      providesTags: ["Categories"],
    }),
    addCategories: builder.mutation({
      query: (body) => ({
        url: "/categories",
        method: "POST",
        body: body,
      }),

      async onQueryStarted({ body }, { dispatch, queryFulfilled }) {
        try {
          const { data: response } = await queryFulfilled;
          dispatch(
            categoriesSlice.util.updateQueryData(
              "getCategories",
              "",
              (draft: any) => {
                draft.push(response?.category);
              }
            )
          );
        } catch (err) {}
      },
    }),
    updateCategories: builder.mutation({
      query: ({ _id, body }) => ({
        url: `/categories/${_id}`,
        method: "PATCH",
        body: body,
      }),
      async onQueryStarted({ _id, body }, { dispatch, queryFulfilled }) {
        try {
          const { data: response } = await queryFulfilled;
          dispatch(
            categoriesSlice.util.updateQueryData(
              "getCategories",
              "",
              (draft: any) => {
                const categories = draft.find(
                  (categories: any) =>
                    categories._id === response?.updatedCategory?._id
                );
                Object.assign(categories, response?.updatedCategory);
              }
            )
          );
        } catch (err) {}
      },
    }),
    deleteCategories: builder.mutation({
      query: ({ _id, body }) => ({
        url: `/categories/${_id}`,
        method: "DELETE",
        // body: body,
      }),
      async onQueryStarted({ _id, body }, { dispatch, queryFulfilled }) {
        try {
          const { data: response } = await queryFulfilled;
          dispatch(
            categoriesSlice.util.updateQueryData(
              "getCategories",
              "",
              (draft: any) => {
                const deletedIndex = draft.findIndex(
                  (categories: any) => categories._id === _id
                );
                draft.splice(deletedIndex, 1);
              }
            )
          );
        } catch (err) {}
      },
    }),
  }),
});

export const {
  useGetCategoriesQuery,
  useAddCategoriesMutation,
  useUpdateCategoriesMutation,
  useDeleteCategoriesMutation,
} = categoriesSlice;
