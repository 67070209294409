import { appSlice } from "./appSlice";
import {
  Action,
  combineReducers,
  configureStore,
  getDefaultMiddleware,
  ThunkAction,
} from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useSelector } from "react-redux";
// import bookingsReducer from "../features/Bookings/bookingSlice";
// import bookingsReducer from './featuresServices/bookingSlice'
// import policiesReducer from "../features/PoliciesSopsFaqs/policiesSlice";
// import blogsReducer from "../features/Blogs/blogsSlice";
// import blogLikesReducer from "../features/Blogs/blogLikesSlice";
// import blogCommentsReducer from "./featuresServices/blogCommentsSlice";
import bannersReducer from "../features/Banners/bannersSlice";
// import categoriesReducer from "../features/Categories/categoriesSlice";
// import servicesReducer from "../features/Services/servicesSlice";
// import serviceProvidersReducer from "../features/ServiceProvider/serviceProvidersSlice";
// import rolesReducer from "../features/RolesPermissions/rolesSliceOld";
// import permissionsReducer from "../features/RolesPermissions/permissionsSlice";
// import usersReducer from "../features/UserManagement/usersSliceOld";
// import authReducer from "./featuresServices/authSliceOld";

// export const store = configureStore({
//     reducer: {
//       blogs: blogsReducer,
//       bookings: bookingsReducer,
//       blogLikes: blogLikesReducer,
//       blogComments: blogCommentsReducer,
//       banners: bannersReducer,
//       categories: categoriesReducer,
//       services: servicesReducer,
//       serviceProviders: serviceProvidersReducer,
//       permissions: permissionsReducer,
//       roles: rolesReducer,
//       users: usersReducer,
//       auth: authReducer,

//     },
//   })

const combinedReducer = combineReducers({
  [appSlice.reducerPath]: appSlice.reducer,
  // policies: policiesReducer,
  // blogs: blogsReducer,
  // bookings: bookingsReducer  ,
  // blogLikes: blogLikesReducer,
  // blogComments: blogCommentsReducer,
  banners: bannersReducer,
  // categories: categoriesReducer,
  // services: servicesReducer,
  // serviceProviders: serviceProvidersReducer,
  // permissions: permissionsReducer,
  // roles: rolesReducer,
  // users: usersReducer,
  // auth: authReducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === "counter/logout") {
    state = undefined;
  }
  return combinedReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(appSlice.middleware),
});

export default store;

export type RootState = ReturnType<typeof rootReducer>;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export type AppDispatch = typeof store.dispatch;

export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;
