import {
  Group,
  useMantineTheme,
  Text,
  ThemeIcon,
  UnstyledButton,
} from "@mantine/core";
import { Dropzone, IMAGE_MIME_TYPE } from "@mantine/dropzone";
import { showNotification } from "@mantine/notifications";
import {
  Camera,
  Gallery,
  GalleryExport,
  GalleryRemove,
  GalleryTick,
  InfoCircle,
} from "iconsax-react";
import { useRef, useState } from "react";
import { ImageCropper } from "./ImageCropper";
import { useUploadImageMutation } from "./imageUploadSlice";

const UploadImage = ({
  userId,
  parentFolder,
  onUploadComplete,
  buttonOnly,
}: {
  userId?: string;
  buttonOnly?: boolean;
  parentFolder: string;
  onUploadComplete: (value: any, caption: string) => void;
}) => {
  const [selectedImg, setSelectedImg] = useState(null);
  const [openImageCropper, setOpenImageCropper] = useState(false);
  const openRef = useRef<() => void>(null);

  const [onUploadImage, uploadImageResults] = useUploadImageMutation();
  const theme = useMantineTheme();
  let imagevalue: any;
  const auth = JSON.parse(localStorage.getItem("auth") || "null");

  const [files, setFiles] = useState<File[]>([]);

  const onDropFile = (files: any) => {
    setFiles(files);
    setOpenImageCropper(true);
  };

  const onSubmitImage = async (value: any, caption: string) => {
    const body =
      userId && auth?.role?.name === "admin"
        ? { images: [value], type: parentFolder, user: userId }
        : { images: [value], type: parentFolder };

    await onUploadImage(body)
      .unwrap()
      .then((res: any) => {
        onUploadComplete(res[0].publicId, caption);
        showNotification({
          id: "upload-image",
          color: "teal",
          title: "Successful",
          message: "Image uploaded successfuly",
          icon: <InfoCircle />,
          autoClose: 2000,
        });
      })
      .catch((err: any) => {
        showNotification({
          id: "upload-image",
          color: "red",
          title: "Failed to upload image",
          message: err?.response?.data?.info || "",
          icon: <InfoCircle />,
          autoClose: 2000,
        });
      });
  };

  return (
    <>
      <Group
        pb="lg"
        position="center"
        style={{ display: buttonOnly ? "none" : "" }}
      >
        <Dropzone
          onReject={(files) => {
            showNotification({
              id: "Bummer",
              color: "red",
              title: "Failed to Add Image",
              message:
                files[0].errors[0].code === "file-too-large"
                  ? "File must be smaller than 2Mb "
                  : "File type must be png, jpeg, svg or webp",
            });
          }}
          openRef={openRef}
          onDrop={onDropFile}
          radius="md"
          accept={IMAGE_MIME_TYPE}
          loading={uploadImageResults.isLoading}
          // maxSize={3 * 1024 ** 2}
        >
          {
            <Group
              position="center"
              spacing="xl"
              style={{ minHeight: 100, pointerEvents: "none" }}
            >
              <Dropzone.Accept>
                <GalleryExport
                  size={50}
                  color={
                    theme.colors[theme.primaryColor][
                      theme.colorScheme === "dark" ? 4 : 6
                    ]
                  }
                />
              </Dropzone.Accept>
              <Dropzone.Reject>
                <GalleryRemove
                  size={50}
                  color={theme.colors.red[theme.colorScheme === "dark" ? 4 : 6]}
                />
              </Dropzone.Reject>
              <Dropzone.Idle>
                {uploadImageResults.isSuccess ? (
                  <GalleryTick
                    size={50}
                    color={
                      theme.colors.teal[theme.colorScheme === "dark" ? 4 : 6]
                    }
                  />
                ) : uploadImageResults.isError ? (
                  <GalleryRemove
                    size={50}
                    color={
                      theme.colors.red[theme.colorScheme === "dark" ? 4 : 6]
                    }
                  />
                ) : (
                  <Gallery size={50} />
                )}
              </Dropzone.Idle>
              {uploadImageResults.isSuccess ? (
                <div>
                  <Text size="md" fw={500} color="teal">
                    Successful
                  </Text>
                  <Text color="dimmed" inline mt={7}>
                    Image uploaded successfuly
                  </Text>
                </div>
              ) : uploadImageResults.isError ? (
                <div>
                  <Text size="md" fw={500} color="red">
                    Failed
                  </Text>
                  <Text color="dimmed" inline mt={7}>
                    Failed to uploaded image
                  </Text>
                </div>
              ) : (
                <div>
                  <Text size="md">
                    Drag image here or click to select files
                  </Text>
                  <Text color="dimmed">Image should not exceed 3 Mb</Text>
                </div>
              )}
            </Group>
          }
        </Dropzone>
      </Group>

      <UnstyledButton
        onClick={() => {
          if (openRef.current) openRef.current();
        }}
        style={{ display: buttonOnly ? "" : "none" }}
      >
        <ThemeIcon
          variant="light"
          color={"dark"}
          py={20}
          px={4}
          radius={"md"}
          style={{ width: "150px" }}
        >
          <Camera variant="Bold" size={20} />
          <Text my={0} mx={6}>
            Edit Image{" "}
          </Text>
        </ThemeIcon>
      </UnstyledButton>

      {files.length > 0 && (
        <ImageCropper
          image={files[0]}
          openForm={openImageCropper}
          closeForm={() => setOpenImageCropper(false)}
          onSubmit={(value, caption) => {
            imagevalue = value;
            onSubmitImage(value, caption);
          }}
        />
      )}
    </>
  );
};

export default UploadImage;
