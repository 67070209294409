import { appSlice } from "../../app/appSlice";
import { usersSlice } from "../UserManagement/usersSlice";

export const authSlice = appSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (body) => ({
        url: "/login",
        method: "POST",
        body: body,
      }),

      async onQueryStarted({ body }, { dispatch, queryFulfilled }) {
        try {
          const { data: response } = await queryFulfilled;
          //save user to local storage
          localStorage.setItem("auth", JSON.stringify(response));
        } catch (err) {}
      },
    }),
    register: builder.mutation({
      query: (body) => ({
        url: "/register",
        method: "POST",
        body: body,
      }),

      async onQueryStarted({ body }, { dispatch, queryFulfilled }) {
        try {
          const { data: response } = await queryFulfilled;

          const authItem = JSON.parse(localStorage.getItem("auth") || "null");

          const role = authItem?.role?.name;

          if (role === "admin") {
            dispatch(usersSlice.util.updateQueryData("getUsers", "", (draft: any) => {
              console.log(draft);
              console.log("hello")
              draft.unshift(response);
              console.log(draft);
            }));
            return;
          }

          //save user to local storage
          localStorage.setItem("auth", JSON.stringify(response));
        } catch (err) {}
      },
    }),
    getUserProfile: builder.query({
      query: () => "/profile",
      providesTags: ["Profile"],
    }),
    editProfile: builder.mutation({
      query: (body) => ({
        url: "/profile",
        method: "PATCH",
        body: body,
      }),

      async onQueryStarted({ body }, { dispatch, queryFulfilled }) {
        try {
          const { data: response } = await queryFulfilled;
          dispatch(
            authSlice.util.updateQueryData(
              "getUserProfile",
              "",
              (draft: any) => {
                Object.assign(draft, response);
              }
            )
          );

          //update user to local storage
          let auth = JSON.parse(localStorage.getItem("auth") || "null");
          auth = Object.assign(auth, response);
          localStorage.setItem("auth", JSON.stringify(auth));
        } catch (err) {}
      },
    }),
    editPassword: builder.mutation({
      query: (body) => ({
        url: "/password",
        method: "PATCH",
        body: body,
      }),
    }),
    requestOtp: builder.mutation({
      query: (body) => ({
        url: "/mobileOTP/request",
        method: "POST",
        body: body,
      }),
    }),

    requestEmailVerification: builder.mutation({
      query: (body) => ({
        url: "/email/request",
        method: "POST",
        body: body,
      }),
    }),

    verifyEmail: builder.mutation({
      query: (body) => ({
        url: "/email/verify",
        method: "POST",
        body: body,
      }),

      async onQueryStarted({ body }, { dispatch, queryFulfilled }) {
        try {
          const { data: response } = await queryFulfilled;

          if (response.success) {
            //update user to local storage
            const auth = JSON.parse(localStorage.getItem("auth") || "null");
            auth.validEmail = true;
            localStorage.setItem("auth", JSON.stringify(auth));
          }
        } catch (err) {}
      },
    }),

    forgotPassword: builder.mutation({
      query: (body) => ({
        url: "/password/reset",
        method: "POST",
        body: body,
      }),
    }),

    setNewPassword: builder.mutation({
      query: (body) => ({
        url: "/password/reset",
        method: "PATCH",
        body: body,
      }),
    }),

    verifyOtp: builder.mutation({
      query: (body) => ({
        url: "/mobileOTP/verify",
        method: "POST",
        body: body,
      }),

      async onQueryStarted({ body }, { dispatch, queryFulfilled }) {
        try {
          const { data: response } = await queryFulfilled;
          //update user to local storage
          const auth = JSON.parse(localStorage.getItem("auth") || "null");
          auth.validPhone = true;
          localStorage.setItem("auth", JSON.stringify(auth));
        } catch (err) {}
      },
    }),
  }),
});

export const {
  useLoginMutation,
  useRegisterMutation,
  useGetUserProfileQuery,
  useEditProfileMutation,
  useEditPasswordMutation,
  useRequestOtpMutation,
  useRequestEmailVerificationMutation,
  useVerifyEmailMutation,
  useVerifyOtpMutation,
  useForgotPasswordMutation,
  useSetNewPasswordMutation,
} = authSlice;
