import { useEffect } from "react";
import FullPageLoader from "../../components/Loaders/FullPageLoader";
import { useLocation, useNavigate } from "react-router-dom";
import { usePermify } from "../../components/PermissionComponent";
import { useGetUserProfileQuery } from "./authSlice";

function OAuthRedirect() {
  const { search, state }: { search: string; state: any } = useLocation();
  const navigate = useNavigate();
  const { setUser } = usePermify();


  const verifyJwt = async () => {
    const query = new URLSearchParams(search);
    const token = query.get("token");
    const userId = query.get("_id");
    const expires = query.get("expires");
    const accountType = query.get("accountType")

    const auth = {
      accountType: accountType,
      isAuthenticated: true,
      token,
      expiresIn: expires,
    };

    localStorage.setItem("auth", JSON.stringify(auth));

    setUser({
      id: userId || "",
      roles: [accountType || ""],
      permissions: [],
    });

    navigate(state || "/", { replace: true });

    console.log(token, userId, expires);

    // await emailVerification({ token })
    //   .unwrap()
    //   .then((res) => {
    //     updateNotification({
    //       id: "verifyingEmail",
    //       color: "teal",
    //       icon: <InfoCircle />,
    //       title: "Successful",
    //       message: "Email verified successfuly",
    //       autoClose: 2000,
    //     });

    //     navigate("/profile");
    //   })
    //   .catch((err) => {
    //     updateNotification({
    //       id: "verifyingEmail",
    //       color: "red",
    //       title: "Failed to verify email",
    //       icon: <InfoCircle />,
    //       message: err?.data?.info || "",
    //       autoClose: 2000,
    //     });

    //     navigate("/profile");
    //   });
  };

  useEffect(() => {
    verifyJwt();
  }, []);

  return <FullPageLoader text="" />;
}

export default OAuthRedirect;
