import { useState } from "react";
import {
  Image,
  Paper,
  Title,
  Button,
  Group,
  Text,
  Modal,
  useMantineTheme,
  TextInput,
  Box,
  PasswordInput,
  Anchor,
  Center,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { showNotification, updateNotification } from "@mantine/notifications";
import { InfoCircle } from "iconsax-react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mantine/hooks";
import Logo from "../../assets/logo/logo1.png";
import { usePermify } from "../../components/PermissionComponent";
import sendFCMToken from "../../components/FCM/sendFcmToken";
import { useLoginMutation } from "./authSlice";
import { closeAllModals, closeModal } from "@mantine/modals";
import LoginWithGoogle from "./LoginWithGoole";
// import {SecureLS} from ""

// var ls = new SecureLS();

export function Login({
  goTo,
  openLogin,
  onCloseLogin,
}: {
  goTo: string;
  openLogin: boolean;
  onCloseLogin: () => void;
}) {
  const navigate = useNavigate();
  const theme = useMantineTheme();
  const { setUser } = usePermify();
  const location = useLocation();
  const state: any = location.state;
  const background = location.state && state.background;

  const [opened, setOpened] = useState(location.pathname === "/sign-in");

  const [login, loginResponse] = useLoginMutation();

  const form = useForm({
    validateInputOnBlur: true,
    initialValues: {
      password: "",
      email: "",
    },
    validate: {
      password: (value) =>
        value.toString().trim().length < 8
          ? "Password should have at least 8 letters"
          : null,
      email: (value) =>
        value.trim().length < 1
          ? "Email is required"
          : /^\S+@\S+$/.test(value)
          ? null
          : "Invalid Email",
    },
  });

  const onLogin = async (values: any) => {
    showNotification({
      id: "login",
      loading: true,
      title: "Signing in",
      message: "Signing in, please wait...",
      autoClose: false,
      disallowClose: true,
    });

    await login(values)
      .unwrap()
      .then(async (res) => {
        //save user permissions to permify
        setUser({
          id: res?.id,
          roles: [res?.role?.name],
          permissions: res?.role?.permissions?.map((p: any) => p.name),
        });

        if ("serviceWorker" in navigator) {
          // Initialize Firebase

          //send FCM token to server
          await sendFCMToken(res.id).catch((err) => console.log(err));
        }

        onCloseLogin();
        // setOpened(false);

        navigate(
          res.role.name === "admin" ? "/admin" : state?.from || goTo || "/",
          {
            replace: true,
          }
        );

        updateNotification({
          id: "login",
          color: "teal",
          title: "Successful",
          icon: <InfoCircle />,
          message: "Signed in successfuly",
          autoClose: 2000,
        });
      })
      .catch((err) => {
        updateNotification({
          id: "login",
          color: "red",
          title: "Failed to Sign in",
          icon: <InfoCircle />,
          message: err?.data?.info || "",
          autoClose: 2000,
        });
      });
  };

  async function loginMethod(e: any) {
    e.preventDefault();
    if (!form.validate().hasErrors) {
      onLogin(form.values);
    }
  }

  return location?.pathname === "/sign-in" ? (
    <Center h={"100%"}>
      <Paper
        p={24}
        radius="md"
        withBorder
        style={{
          width: "clamp(280px, 100%, 500px)",
          margin: "auto",
          background: "transparent",
        }}
      >
        <Image
          src={Logo}
          alt="wearefitabo.com"
          mx={"auto"}
          my={24}
          width={200}
        />
        <Title my={12} align="center" order={4}>
          {state?.head || "Sign in to your account"}
        </Title>
        <Text align="center" color={"dimmed"}>
          {state?.subHead || "Welcome back! Please enter your details."}
        </Text>
        <Box pt={24}>
          <form style={{ width: "100%" }} onSubmit={loginMethod}>
            <TextInput
              label="Email"
              placeholder="example@mail.com"
              {...form.getInputProps("email")}
            />
            <PasswordInput
              placeholder="Password"
              label="Password"
              my={12}
              {...form.getInputProps("password")}
            />
            <Button
              fullWidth
              size="lg"
              radius={"md"}
              mt={24}
              type="submit"
              loading={loginResponse.isLoading}
            >
              Sign in
            </Button>
            <LoginWithGoogle />
          </form>
        </Box>
        <Group position="center" mt={24} align={"middle"}>
          <Text>Don’t have an account?</Text>
          <Anchor
            component={Link}
            to="/sign-up"
            state={{ background: background }}
            replace
          >
            Sign up
          </Anchor>
        </Group>
      </Paper>
    </Center>
  ) : (
    <Modal
      onClose={() => {
        onCloseLogin();
      }}
      opened={location?.pathname === "/sign-in" ? false : opened || openLogin}
      centered
      withCloseButton={false}
      transition="rotate-left"
      transitionDuration={500}
      transitionTimingFunction="ease"
      overlayColor={
        theme.colorScheme === "dark"
          ? theme.colors.dark[9]
          : theme.colors.gray[2]
      }
      overflow="inside"
      overlayOpacity={0.55}
      overlayBlur={3}
      padding={0}
      radius="md"
    >
      <Center h={"100%"}>
        <Paper
          p={24}
          radius="md"
          withBorder
          style={{
            width: "clamp(280px, 100%, 500px)",
            margin: "auto",
            background: "transparent",
          }}
        >
          <Image
            src={Logo}
            alt="wearefitabo.com"
            mx={"auto"}
            my={24}
            width={200}
          />
          <Title my={12} align="center" order={4}>
            {state?.head || "Sign in to your account"}
          </Title>
          <Text align="center" color={"dimmed"}>
            {state?.subHead || "Welcome back! Please enter your details."}
          </Text>
          <Box pt={24}>
            <form style={{ width: "100%" }} onSubmit={loginMethod}>
              <TextInput
                label="Email"
                placeholder="example@mail.com"
                {...form.getInputProps("email")}
              />
              <PasswordInput
                placeholder="Password"
                label="Password"
                my={12}
                {...form.getInputProps("password")}
              />

              <Anchor
                component={Link}
                to="/forgotpassword"
                state={{ background: background }}
                replace
              >
                Forgot Password?
              </Anchor>

              <Button
                fullWidth
                size="lg"
                radius={"md"}
                mt={16}
                type="submit"
                loading={loginResponse.isLoading}
              >
                Sign in
              </Button>
              <LoginWithGoogle />
            </form>
          </Box>
          <Group position="center" mt={24} align={"middle"}>
            <Text>Don’t have an account?</Text>
            <Anchor
              component={Link}
              to="/sign-up"
              state={{ background: background }}
              replace
            >
              Sign up
            </Anchor>
          </Group>
        </Paper>
      </Center>
    </Modal>
  );
}
