import {Center, Container, Loader, Image, LoadingOverlay} from "@mantine/core";
import Logo from "../../assets/logo/logo.png"


export default function FullPageLoader({text}: { text: string }) {

    return (
        // <div style={{width:'100vw !important', top:'180px', height:'80vh !important', display:'flex !important' , justifyContent:'center !important', alignItems:'center !important', background:'blue',position:'absolute'}}>
//         {/* <LoadingOverlay visible={true}       overlayOpacity={0}
// /> */}
<Center color="red" style={{height:'60vh', margin:'0px'}}>
<Loader color={'#F4816D'} visibility={1} style={{marginTop:'-9.5px', marginLeft:'0.75px', }}>
</Loader>
<Image width={26} src={Logo} style={{position:'absolute',opacity:'0.5', }}/>

</Center>
        // </div>
    )
}
