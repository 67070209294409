import React, { useRef, useState } from "react";
import {
  Container,
  Button,
  Group,
  Modal,
  useMantineTheme,
  ActionIcon,
  TextInput,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import {
  IconZoomIn,
  IconZoomOut,
  IconRefresh,
  IconArrowUp,
  IconArrowDown,
  IconArrowLeft,
  IconArrowRight,
} from "@tabler/icons";
import { showNotification } from "@mantine/notifications";
import { useForm } from "@mantine/form";
import { useLocation } from "react-router-dom";

interface ImageCropperProps {
  image: File;
  onSubmit(values: any, caption: string): void;
  openForm: boolean;
  closeForm: () => void;
}

export function ImageCropper({
  image,
  onSubmit,
  openForm,
  closeForm,
}: ImageCropperProps) {
  const isMobile = useMediaQuery("(max-width: 755px");
  const theme = useMantineTheme();
  const [cropper, setCropper] = useState<any>();
  const location = useLocation();

  const form = useForm({
    initialValues: { caption: "" },

    validate: {
      caption: (value) =>
        value.trim().length < 3 ? "Caption must have at least 2 letters" : null,
    },
  });

  return (
    <Modal
      onClose={() => closeForm()}
      title="Edit Image"
      opened={openForm}
      centered
      overflow="inside"
      transition="slide-down"
      transitionDuration={300}
      transitionTimingFunction="ease"
      size={"xl"}
      radius="lg"
      overlayColor={
        theme.colorScheme === "dark"
          ? theme.colors.dark[9]
          : theme.colors.gray[2]
      }
      overlayOpacity={0.55}
      overlayBlur={3}
      fullScreen={isMobile}
    >
      <Container>
        {image && (
          <Cropper
            style={{ height: 400, width: "100%" }}
            zoomTo={0.1}
            initialAspectRatio={1}
            preview=".img-preview"
            src={URL.createObjectURL(image)}
            viewMode={1}
            minCropBoxHeight={10}
            minCropBoxWidth={10}
            background={false}
            responsive={true}
            // crop={onCrop}
            // ref={cropperRef}
            autoCropArea={1}
            checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
            onInitialized={(instance: any) => {
              setCropper(instance);
            }}
            guides={true}
          />
        )}

        <Group position="apart" py="sm" align="end">
          <div>
            <Group spacing={6} grow noWrap>
              <ActionIcon
                size="lg"
                radius="md"
                color="primary"
                onClick={() => {
                  cropper.zoom(0.1);
                }}
              >
                <IconZoomIn size={24} />
              </ActionIcon>
              <ActionIcon
                size="lg"
                radius="md"
                color="primary"
                onClick={() => {
                  cropper.zoom(-0.1);
                }}
              >
                <IconZoomOut size={24} />
              </ActionIcon>
              <ActionIcon
                size="lg"
                radius="md"
                color="primary"
                onClick={() => {
                  cropper.move(-10, 0);
                }}
              >
                <IconArrowLeft size={24} />
              </ActionIcon>
              <ActionIcon
                size="lg"
                radius="md"
                color="primary"
                onClick={() => {
                  cropper.move(10, 0);
                }}
              >
                <IconArrowRight size={24} />
              </ActionIcon>
              <ActionIcon
                size="lg"
                radius="md"
                color="primary"
                onClick={() => {
                  cropper.move(0, -10);
                }}
              >
                <IconArrowUp size={24} />
              </ActionIcon>
              <ActionIcon
                size="lg"
                radius="md"
                color="primary"
                onClick={() => {
                  cropper.move(0, 10);
                }}
              >
                <IconArrowDown size={24} />
              </ActionIcon>

              <ActionIcon
                size="lg"
                radius="md"
                color="primary"
                onClick={() => {
                  cropper.reset();
                }}
              >
                <IconRefresh size={24} />
              </ActionIcon>
            </Group>
            {location.pathname.includes("register") ? null : (
              <TextInput
                mt="xs"
                variant="filled"
                size="md"
                label="Image Caption"
                placeholder="Image title/ caption/ details"
                {...form.getInputProps("caption")}
              />
            )}
          </div>
          <Group>
            <Button
              radius="md"
              variant="light"
              color="dark"
              onClick={() => {
                // cropper.clear();
                closeForm();
              }}
            >
              Cancel
            </Button>
            <Button
              radius="md"
              disabled={
                !form.isValid() && !location.pathname.includes("register")
              }
              onClick={() => {
                const value = cropper?.getCroppedCanvas().toDataURL(image.type);
                // check image size formular x = (n *(3/4)) - y
                const imageType = value.split(",")[0];

                const base64String = value.split(",")[1];
                const stringLength = base64String.length;
                const padding = (stringLength * (3 / 4)) % 4;
                const fileSize = stringLength * (3 / 4) - padding;
                const sizeInKB = fileSize / 1024;

                if (fileSize > 5242880) {
                  showNotification({
                    id: "img-cropper",
                    color: "red",
                    title: "Failed to Add Image",
                    message: "File must be smaller than 5Mb ",
                  });
                } else {
                  onSubmit(value, form.values.caption);
                  form.reset();
                }

                closeForm();
              }}
            >
              Confirm
            </Button>
          </Group>
        </Group>
      </Container>
    </Modal>
  );
}
