/*
 * This function subscribes client to firebase cloud messaging
 * 1.Obtain a token using a public vapid key from firebase
 * 2.Permission is implicitly requested in the process
 * 3.Send token to the server
 */

//firebase imports
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import { subscribePushNotitication } from "../../api/pushNotificationApi";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD8Mrs-F-xiO0O3xWGJbIlqjgv0U7Gkun4",
  authDomain: "wearefitabo-76de2.firebaseapp.com",
  projectId: "wearefitabo-76de2",
  storageBucket: "wearefitabo-76de2.appspot.com",
  messagingSenderId: "657921022646",
  appId: "1:657921022646:web:64130af87cd12c9e7ce417",
  measurementId: "G-5MXMWSZZ8K",
};

export default async function sendFCMToken(userId: String) {
  try {
    initializeApp(firebaseConfig);

    const messaging = getMessaging();

    //public vapidKey
    const vapidKey =
      "BACtWzM7T35NwYyBzUC9K3bqL6hoT2vCZ-6VaNnsE6F-Phti8datBsJs79XZ2KfzludUi3ydOduuO9bqo7Wy4sw";

    const token = await getToken(messaging, { vapidKey });

    if (!token) {
      //request permission
      return;
    }

    //send Token to the server
    const response = await subscribePushNotitication(token, userId);

    return response();
  } catch (error) {
    // console.log(error);
  }
}
