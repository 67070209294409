import { appSlice } from "../../app/appSlice";

export const servicesSlice = appSlice.injectEndpoints({
  endpoints: (builder) => ({
    getServices: builder.query({
      query: () => "/services",
      providesTags: ["Services"],
    }),
    getRandomServices: builder.query({
      query: ({ limit }) => `/services?q=random&limit=${limit}`,
      providesTags: ["Services"],
    }),
    addServices: builder.mutation({
      query: (body) => ({
        url: "/services",
        method: "POST",
        body: body,
      }),

      async onQueryStarted({ body }, { dispatch, queryFulfilled }) {
        try {
          const { data: response } = await queryFulfilled;
          dispatch(
            servicesSlice.util.updateQueryData(
              "getServices",
              "",
              (draft: any) => {
                draft.push(response?.service);
              }
            )
          );
        } catch (err) {}
      },
    }),
    updateServices: builder.mutation({
      query: ({ _id, body }) => ({
        url: `/services/${_id}`,
        method: "PATCH",
        body: body,
      }),
      async onQueryStarted({ _id, body }, { dispatch, queryFulfilled }) {
        try {
          const { data: response } = await queryFulfilled;
          dispatch(
            servicesSlice.util.updateQueryData(
              "getServices",
              "",
              (draft: any) => {
                const services = draft.find(
                  (service: any) => service._id === _id
                );
                Object.assign(services, response?.updatedService);
              }
            )
          );
        } catch (err) {}
      },
    }),
    deleteServices: builder.mutation({
      query: ({ _id, body }) => ({
        url: `/services/${_id}`,
        method: "DELETE",
      }),
      async onQueryStarted({ _id, body }, { dispatch, queryFulfilled }) {
        try {
          dispatch(
            servicesSlice.util.updateQueryData(
              "getServices",
              "",
              (draft: any) => {
                const deletedIndex = draft.findIndex(
                  (services: any) => services._id === _id
                );
                draft.splice(deletedIndex, 1);
              }
            )
          );
        } catch (err) {}
      },
    }),
  }),
});

export const {
  useGetServicesQuery,
  useGetRandomServicesQuery,
  useAddServicesMutation,
  useUpdateServicesMutation,
  useDeleteServicesMutation,
} = servicesSlice;
