import { appSlice } from "../../app/appSlice";

const auth = JSON.parse(localStorage.getItem("auth") || "null");

export const serviceProvidersSlice = appSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllProviders: builder.query({
      query: () => "/providers",
      providesTags: ["Providers"],
    }),
    getApprovedProviders: builder.query({
      query: ({ status }) => `/providers?status=${status}`,
      providesTags: ["Providers"],
    }),
    getProvidersProfile: builder.query({
      query: () => "/profile/provider",
      providesTags: ["Profile"],
    }),
    addProvider: builder.mutation({
      query: (body) => ({
        url: "/providers",
        method: "POST",
        body: body,
      }),
      //remeber to add check which user is adding provider and update
      async onQueryStarted({ body }, { dispatch, queryFulfilled }) {
        try {
          const { data: addedProvider } = await queryFulfilled;
          if (auth?.role?.name === "admin") {
            const patchResult = dispatch(
              serviceProvidersSlice.util.updateQueryData(
                "getAllProviders",
                "",
                (draft: any) => {
                  console.log(addedProvider)
                  draft.push(addedProvider?.provider);
                }
              )
            );
          } else {
            const patchResult = dispatch(
              serviceProvidersSlice.util.updateQueryData(
                "getProvidersProfile",
                "",
                (draft: any) => {
                  Object.assign(draft, addedProvider?.serviceProvider);
                  Object.assign(auth, { registeredServiceProvider: true });
                }
              )
            );
          }
        } catch (err) {}
      },
    }),
    verifyProvider: builder.mutation({
      query: ({ _id, body }) => ({
        url: `/providers/verification`,
        method: "PATCH",
        body: body,
      }),
      async onQueryStarted({ _id, body }, { dispatch, queryFulfilled }) {
        try {
          const { data: response } = await queryFulfilled;
          dispatch(
            serviceProvidersSlice.util.updateQueryData(
              "getAllProviders",
              "",
              (draft: any) => {
                const provider = draft.find(
                  (provider: any) => provider._id === _id
                );
                Object.assign(provider, {
                  verificationStatus: response.verificationStatus,
                });
              }
            )
          );
        } catch (err) {}
      },
    }),

    updateProvider: builder.mutation({
      query: ({ _id, body }) => ({
        url: `/providers/${_id}`,
        method: "PATCH",
        body: body,
      }),
      //remeber to add check which user is adding provider and update

      async onQueryStarted({ _id, body }, { dispatch, queryFulfilled }) {
        try {
          const { data: response } = await queryFulfilled;
          if (auth?.role?.name === "admin") {
            dispatch(
              serviceProvidersSlice.util.updateQueryData(
                "getAllProviders",
                "",
                (draft: any) => {
                  const provider = draft.find(
                    (provider: any) => provider._id === _id
                  );
                  Object.assign(provider, response.provider);
                }
              )
            );
          } else {
            dispatch(
              serviceProvidersSlice.util.updateQueryData(
                "getProvidersProfile",
                "",
                (draft: any) => {
                  Object.assign(draft, response.provider);
                }
              )
            );
          }
        } catch (err) {}
      },
    }),

    deleteProviderImage: builder.mutation({
      query: ({ _id, body }) => ({
        url: `/providers/${_id}/image`,
        method: "DELETE",
        body: body,
      }),
      //remeber to add check which user is deleting image

      async onQueryStarted({ _id, body }, { dispatch, queryFulfilled }) {
        try {
          const { data: response } = await queryFulfilled;
          if (auth?.role?.name === "admin") {
            dispatch(
              serviceProvidersSlice.util.updateQueryData(
                "getAllProviders",
                "",
                (draft: any) => {
                  const provider = draft.find(
                    (provider: any) => provider._id === _id
                  );
                  Object.assign(provider, response.provider);
                }
              )
            );
          } else {
            dispatch(
              serviceProvidersSlice.util.updateQueryData(
                "getProvidersProfile",
                "",
                (draft: any) => {
                  Object.assign(draft, response.provider);
                }
              )
            );
          }
        } catch (err) {}
      },
    }),

    suspendProvider: builder.mutation({
      query: ({ _id, body }) => ({
        url: `/providers/${_id}`,
        method: "DELETE",
        data: body,
      }),
      async onQueryStarted({ _id, body }, { dispatch, queryFulfilled }) {
        try {
          const { data: response } = await queryFulfilled;
          dispatch(
            serviceProvidersSlice.util.updateQueryData(
              "getAllProviders",
              "",
              (draft: any) => {
                const provider = draft.find(
                  (provider: any) => provider._id === response?.provider?._id
                );
                Object.assign(provider, body);
              }
            )
          );
        } catch (err) {}
      },
    }),
  }),
});

export const {
  useGetAllProvidersQuery,
  useGetApprovedProvidersQuery,
  useGetProvidersProfileQuery,
  useAddProviderMutation,
  useVerifyProviderMutation,
  useUpdateProviderMutation,
  useDeleteProviderImageMutation,
  useSuspendProviderMutation,
} = serviceProvidersSlice;
