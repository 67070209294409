import React, { ReactElement, ReactNode, useState } from "react";
import {
  Button,
  Group,
  Text,
  useMantineTheme,
  Box,
  Image,
  Modal,
  Container,
  Title,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { showNotification, updateNotification } from "@mantine/notifications";
import { InfoCircle } from "iconsax-react";
import "react-phone-number-input/style.css";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import Logo from "../../assets/logo/logo1.png";
import { Otp } from "./Otp";
import {
  useRequestOtpMutation,
  useVerifyOtpMutation,
} from "../../features/auth/authSlice";
import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";

interface VerifyMobileNumberProps {
  showAsModal: boolean;
  user: any;
  opened?: boolean;
  closeModal?: () => void;
  onVerified?: () => void;
}

export function VerifyMobileNumber({
  showAsModal,
  user,
  opened,
  closeModal,
  onVerified,
}: VerifyMobileNumberProps) {
  const theme = useMantineTheme();
  const [otpShow, setOtpShow] = useState(false);
  const [code, setCode] = useState("");
  const [otp, setOtp] = useState("");

  const [requestOtp] = useRequestOtpMutation();
  const [verifyOtp, verifyResponse] = useVerifyOtpMutation();

  const form: any = useForm({
    validateInputOnBlur: true,
    initialValues: {
      password: "",
      confirmPassword: "",
      email: "",
      firstname: "",
      lastname: "",
      phone: "",
      country: "",
      termsOfService: false,
    },
    validate: {
      firstname: (value) =>
        value.trim().length < 1 ? "Firstname required" : null,
      lastname: (value) =>
        value.trim().length < 1 ? "Lastname required" : null,
      email: (value) =>
        value.trim().length < 1
          ? "Email is required"
          : /^\S+@\S+$/.test(value)
          ? null
          : "Invalid Email",
      phone: (value) =>
        !value
          ? "Phone number required"
          : isPossiblePhoneNumber(value.toString()) === true
          ? null
          : "Not valid phone number",
      password: (value) =>
        value.toString().trim().length < 8
          ? "Password should have at least 8 characters"
          : /[$&+,:;=?@#|'<>.^*()%!-]/.test(value.toString().trim())
          ? null
          : "Password should contain special character",
      confirmPassword: (value) =>
        value.toString().trim() !== form.values.password
          ? "Password does not match"
          : null,
      termsOfService: (value: any) =>
        value === false ? "Please read and accept our terms of service" : null,
    },
  });

  const onVerifyOtp = async (value: any) => {
    if (value.length === 6) {
      showNotification({
        id: "verifyOtp",
        loading: true,
        title: "Verify Phone Number",
        message: "Verifying your phone number ...",
        autoClose: false,
        disallowClose: true,
      });
      await verifyOtp({ user: user._id || user.id, pin: value })
        .unwrap()
        .then((res) => {
          updateNotification({
            id: "verifyOtp",
            color: "teal",
            icon: <InfoCircle />,
            title: "Successful",
            message: "Phone Number verified successfuly",
            autoClose: 2000,
          });

          if (onVerified) {
            onVerified();
          }
          if (showAsModal && closeModal) {
            closeModal();
          }
        })
        .catch((err) => {
          updateNotification({
            id: "verifyOtp",
            color: "red",
            title: "Failed to verify phone number",
            icon: <InfoCircle />,
            message: err?.data?.info || "",
            autoClose: 2000,
          });
        });
    } else {
      setOtpShow(true);
    }
  };

  const onRequestOtp = async () => {
    showNotification({
      id: "requestOtp",
      loading: true,
      title: "Request OTP",
      message: "Sending OTP...",
      autoClose: false,
      disallowClose: true,
    });

    await requestOtp({ user: user._id || user.id })
      .unwrap()
      .then((res) => {
        updateNotification({
          id: "requestOtp",
          color: "teal",
          icon: <InfoCircle />,
          title: "Successful",
          message: "OTP sent successfuly",
          autoClose: 2000,
        });
      })
      .catch((err) => {
        updateNotification({
          id: "requestOtp",
          color: "red",
          title: "Failed to send OTP",
          icon: <InfoCircle />,
          message: err?.data?.info || "",
          autoClose: 2000,
        });
      });
  };

  const verifyBody = () => {
    return (
      <Container px={"sm"} style={{ minHeight: "400px", overflow: "hidden" }}>
        <Group
          position="apart"
          align={"center"}
          style={{
            maxWidth: "90%",
            margin: "auto",
            flexDirection: "column",
          }}
        >
          <Title order={2} mt={"xl"} align="center">
            Enter the OTP
          </Title>
          <Text
            align="center"
            size={"sm"}
            mb={"xl"}
            color={"dimmed"}
            style={{ maxWidth: "400px", margin: "auto" }}
          >
            A One Time Password has been sent to your phone number for
            verification puposes
          </Text>
          <Box py={16} />

          <Box my={12}>
            <Otp
              otp={otp}
              setOtp={(val: any) => {
                setOtp(val);
                if (val.length === 6) {
                  onVerifyOtp(val);
                }
              }}
            />

            <Group my={12} position="center">
              Didn't receive an OTP?{" "}
              <Button
                variant="subtle"
                onClick={onRequestOtp}
                color="primary"
                style={{ textTransform: "none", fontSize: 15 }}
              >
                Resend OTP
              </Button>
            </Group>
          </Box>
          <Group position="center">
            <Button
              size="lg"
              radius={"md"}
              mt={24}
              disabled={otp.length !== 6}
              loading={verifyResponse?.isLoading || false}
              color="secondary"
              style={{
                color: "white",
                margin: "auto",
                width: "200px",
                textTransform: "none",
              }}
              onClick={() => onVerifyOtp(otp)}
            >
              Verify
            </Button>
          </Group>
        </Group>
      </Container>
    );
  };

  return (
    <div>
      {showAsModal ? (
        <Modal
          onClose={() => closeModal && closeModal()}
          opened={opened || false}
          size={"xl"}
          radius="lg"
          centered
          title={
            <Image
              src={Logo}
              alt="wearefitabo.com"
              ml={4}
              my={12}
              width={150}
            />
          }
          transition="rotate-left"
          transitionDuration={300}
          transitionTimingFunction="ease"
          overflow="inside"
          overlayColor={
            theme.colorScheme === "dark"
              ? theme.colors.dark[9]
              : theme.colors.gray[2]
          }
          overlayOpacity={0.55}
          overlayBlur={3}
          withCloseButton={true}
        >
          {verifyBody()}
        </Modal>
      ) : (
        <>{verifyBody()}</>
      )}
    </div>
  );
}
