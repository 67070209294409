import {
  SimpleGrid,
  Title,
  Group,
  Button,
  Divider,
  Text,
  Card,
  Badge,
  Avatar,
  Center,
  Container,
  Loader,
  Grid,
  AspectRatio,
} from "@mantine/core";
import { showNotification, updateNotification } from "@mantine/notifications";
import { ArrowLeft, Clock, Edit, InfoCircle, Trash } from "iconsax-react";
import React, { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { HasAccess } from "../../components/PermissionComponent";
import { NoData } from "../../components/NoData";
import { opencustomConfirmModal } from "../../components/OpenCustomConfirmModal";
import { ServiceForm } from "./ServiceForm";
import ServiceProviderCard from "../ServiceProvider/ServiceProviderCard";
import FullLoader from "../../components/Loaders/FullLoader";
import { useGetCategoriesQuery } from "../Categories/categoriesSlice";
import {
  useDeleteServicesMutation,
  useGetServicesQuery,
  useUpdateServicesMutation,
} from "./servicesSlice";
import { ErrorPage } from "../../components/ErrorPage";
import { useGetApprovedProvidersQuery } from "../ServiceProvider/serviceProvidersSlice";

let initialValues: any = {
  name: "",
  category: "",
  duration: 0,
  image: "",
  description: "",
  categoriesData: [],
};

const servicesFields = ["name", "category", "description", "image", "duration"];
let actionPerformed = "edit";

export function ServiceDetails() {
  const [openServiceForm, setOpenServiceForm] = useState(false);
  const { serviceId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const { data: categories }: any = useGetCategoriesQuery("");
  const {
    data: services,
    isLoading: isLoadingServices,
    isError: isErrorServices,
    error: errorServices,
  }: any = useGetServicesQuery("");
  const {
    data: providers,
    isLoading: isLoadingServicesProviders,
    isError: isErrorServicesProviders,
    error: errorServicesProviders,
  }: any = useGetApprovedProvidersQuery({ status: "approved" });

  const service = services?.find((service: any) => service?._id === serviceId);
  const serviceProviders = providers?.filter((provider: any) =>
    provider?.services?.find(
      (service: any) => service?.service?._id === serviceId
    )
  );

  const [updateService] = useUpdateServicesMutation();
  const [deleteService] = useDeleteServicesMutation();

  const onSubmitForm = async (values: any) => {
    const infoTitle = actionPerformed === "edit" ? "Update" : "delete";
    const infoMessage = actionPerformed === "edit" ? "Updating" : "Deleting";
    showNotification({
      id: "edit-service",
      loading: true,
      title: `${infoTitle} Service`,
      message: `${infoMessage} Service, please wait...`,
      autoClose: false,
      disallowClose: true,
    });
    try {
      if (actionPerformed === "edit") {
        let filtered: any;

        servicesFields.map((field: any) => {
          if (field === "image" && values?.image?.includes("https://")) {
            return;
          }
          filtered = {
            ...filtered,
            ...Object.fromEntries(
              Object.entries(values).filter(([key]) => key.includes(field))
            ),
          };
        });

        await updateService({ _id: serviceId, body: filtered }).unwrap();
      }
      actionPerformed === "delete" && navigate(-1);
      actionPerformed === "delete" &&
        (await deleteService({
          _id: serviceId,
          body: { deleted: true },
        }).unwrap());

      updateNotification({
        id: "edit-service",
        color: "teal",
        title: "Successful",
        icon: <InfoCircle />,
        message: `${infoMessage} Service, successful`,
        autoClose: 2000,
      });
      actionPerformed = "edit";
      setOpenServiceForm(false);
    } catch (err) {
      const error: any = err;
      updateNotification({
        id: "edit-service",
        color: "red",
        title: `Failed to ${infoTitle} Service`,
        icon: <InfoCircle />,
        message: error?.data?.info || "",
        autoClose: 3000,
      });
    }
  };

  async function openForm() {
    if (service) {
      const tempCategories: any = categories;
      tempCategories.map((item: any) => {
        const objItem = {};
        objItem["value"] = item._id;
        objItem["label"] = item.name;
        objItem["group"] = item.description;
        if (
          !initialValues.categoriesData.find(
            (category: any) => category.value === item._id
          )
        ) {
          initialValues.categoriesData.push(objItem);
        }
      });

      initialValues = {
        _id: service._id,
        name: service.name,
        category: service.category["_id"] || "",
        image: service?.image?.md,
        duration: service.duration,
        description: service.description,
        categoriesData: initialValues.categoriesData,
      };
      setOpenServiceForm(true);
    }
  }

  return (
    <div>
      {location?.pathname?.includes("admin") && (
        <HasAccess permissions={["can_edit_service"]}>
          <Card shadow="sm" p={0} radius="lg" className="backToList">
            <SimpleGrid p={"lg"} breakpoints={[{ minWidth: "sm", cols: 2 }]}>
              <Group>
                <Button
                  radius="md"
                  leftIcon={<ArrowLeft />}
                  variant="light"
                  onClick={() => navigate(-1)}
                >
                  Back to List
                </Button>
                <Divider orientation="vertical" />
                <div style={{ margin: "0px" }}>
                  <Text color={"dimmed"} size="sm">
                    Last Updated:{" "}
                  </Text>
                  {service && (
                    <Text size="sm" weight={600}>
                      {new Date(service!["updatedAt"]).toDateString() || ""}
                    </Text>
                  )}
                </div>
              </Group>

              <Group align={"center"} position="right">
                <HasAccess permissions={["can_edit_service"]}>
                  <Button
                    radius="md"
                    leftIcon={<Edit />}
                    variant="light"
                    onClick={() => {
                      opencustomConfirmModal(
                        "Please confirm your action",
                        "Are you sure you want to edit this services?",
                        () => openForm()
                      );
                    }}
                    styles={(theme) => ({
                      root: {
                        webkitTransition: "all 0.4s",
                        transition: "all 0.4s",
                        float: "right",

                        "&:hover": {
                          backgroundColor: theme.fn.darken("#F4816D", 0.05),
                          color: "#fff",
                        },
                      },

                      leftIcon: {
                        marginRight: 15,
                      },
                    })}
                  >
                    Edit
                  </Button>
                </HasAccess>
                <HasAccess permissions={["can_edit_service"]}>
                  <Button
                    radius="md"
                    leftIcon={<Trash />}
                    color="red"
                    variant="light"
                    onClick={() => {
                      actionPerformed = "delete";
                      onSubmitForm("");
                    }}
                    styles={(theme) => ({
                      root: {
                        webkitTransition: "all 0.4s",
                        transition: "all 0.4s",
                        float: "right",

                        "&:hover": {
                          backgroundColor: "red",
                          color: "#fff",
                        },
                      },

                      leftIcon: {
                        marginRight: 15,
                      },
                    })}
                  >
                    Delete
                  </Button>
                </HasAccess>
              </Group>
            </SimpleGrid>
          </Card>
        </HasAccess>
      )}
      {isLoadingServices ? (
        <FullLoader />
      ) : isErrorServices ? (
        <ErrorPage
          errorCode={
            errorServices["originalStatus"] || errorServices["status"] || 404
          }
        />
      ) : (
        <div>
          {service ? (
            <Container size={"xl"} px="xl" my={24}>
              <Grid>
                <Grid.Col md={4}>
                  <AspectRatio ratio={16 / 12}>
                    <LazyLoadImage
                      effect={"blur"}
                      width={"100%"}
                      height={"100%"}
                      placeholderSrc={service?.image?.sm}
                      src={service?.image?.lg}
                      style={{ objectFit: "cover" }}
                    />
                  </AspectRatio>
                </Grid.Col>
                <Grid.Col md={8}>
                  <div>
                    <Title tt="capitalize" style={{ marginTop: 2 }}>
                      {service?.name || ""}
                    </Title>
                    <Group pb="md">
                      <HasAccess
                        permissions={["can_edit_service"]}
                        renderAuthFailed={
                          <Badge
                            variant="dot"
                            radius={"md"}
                            size="xl"
                            styles={(theme) => ({
                              root: {
                                transition: "0.4s",
                                "&:hover": {
                                  textDecoration: "none !important",
                                  transform: "scale(1.05)",
                                },
                              },

                              leftIcon: {
                                marginRight: 15,
                              },
                            })}
                          >
                            {service.category.name}
                          </Badge>
                        }
                      >
                        <Badge
                          variant="dot"
                          radius={"md"}
                          size="xl"
                          component="a"
                          href={`/admin/services/categories/${service.category._id}`}
                          styles={(theme) => ({
                            root: {
                              transition: "0.4s",
                              "&:hover": {
                                cursor: "pointer",
                                textDecoration: "none !important",
                                transform: "scale(1.05)",
                              },
                            },

                            leftIcon: {
                              marginRight: 15,
                            },
                          })}
                        >
                          {service.category.name}
                        </Badge>
                      </HasAccess>

                      <Badge
                        sx={{ paddingLeft: 0 }}
                        size="xl"
                        p="md"
                        radius="md"
                        leftSection={
                          <Avatar color={"#FF8A65"} size={24}>
                            <Clock size="24" />
                          </Avatar>
                        }
                        styles={(theme) => ({
                          root: {
                            transition: "0.4s",
                            "&:hover": {
                              textDecoration: "none !important",
                              transform: "scale(1.05)",
                            },
                          },

                          leftIcon: {
                            marginRight: 15,
                          },
                        })}
                      >
                        <Text transform="lowercase">
                          {" "}
                          {Math.floor(parseInt(service.duration) / 60) > 0
                            ? `${Math.floor(
                                parseInt(service.duration) / 60
                              )}hr  `
                            : ""}
                          {parseInt(service.duration) % 60 > 0
                            ? `${parseInt(service.duration) % 60}min`
                            : ""}{" "}
                        </Text>
                      </Badge>
                    </Group>

                    <p
                      dangerouslySetInnerHTML={{
                        __html: service?.description || "",
                      }}
                    />
                  </div>
                </Grid.Col>
              </Grid>
            </Container>
          ) : (
            <Center>
              <Loader />
            </Center>
          )}

          <HasAccess permissions={["can_edit_service"]}>
            {service && (
              <ServiceForm
                initialValues={initialValues}
                fields={servicesFields}
                title="Edit Service"
                openForm={openServiceForm}
                closeForm={() => setOpenServiceForm(false)}
                onSubmit={(values) => {
                  actionPerformed = "edit";
                  onSubmitForm(values);
                }}
              />
            )}
          </HasAccess>
        </div>
      )}
      <div style={{ padding: "0px 5%" }}>
        <SimpleGrid
          pt={24}
          style={{ width: "100%" }}
          breakpoints={[
            { maxWidth: 600, cols: 1, spacing: "lg" },
            { maxWidth: 755, cols: 2, spacing: "sm" },
            { maxWidth: 900, cols: 2, spacing: 24 },
            { maxWidth: 1124, cols: 3, spacing: 0 },
            { maxWidth: 1240, cols: 3, spacing: "sm" },
            { maxWidth: 1580, cols: 4, spacing: "sm" },
            { minWidth: 1580, cols: 5, spacing: "md" },
          ]}
        >
          {isLoadingServicesProviders ? (
            <FullLoader />
          ) : isErrorServicesProviders ? (
            <ErrorPage
              errorCode={
                errorServicesProviders?.originalStatus ||
                errorServicesProviders?.status ||
                404
              }
            />
          ) : (
            serviceProviders?.map((item: any, i: any) => (
              <ServiceProviderCard
                key={i}
                data={item}
                loading={isLoadingServicesProviders}
              />
            ))
          )}
          {serviceProviders?.length === 0 && (
            <NoData message="No providers available." />
          )}
        </SimpleGrid>
      </div>
    </div>
  );
}
