import {
  createStyles,
  Image,
  Container,
  Title,
  Text,
  Button,
  SimpleGrid,
} from "@mantine/core";
import { useNavigate } from "react-router-dom";
import badRequest from "../assets/error/400 Error Bad Request-pana.svg";
import unauthorized from "../assets/error/401 Error Unauthorized-pana.svg";
import forbidden from "../assets/error/403 Error Forbidden-pana.svg";
import notFound from "../assets/error/404 Error page not found.svg";
import tooManyRequest from "../assets/error/429 Error too many request.svg";
import internalServerError from "../assets/error/500 Internal Server Error-pana.svg";
import serviceUnavailable from "../assets/error/503 Error Service Unavailable-cuate.svg";
import gatewayTimeout from "../assets/error/504 Error Gateway Timeout-pana.svg";

const useStyles = createStyles((theme) => ({
  root: {
    paddingTop: 80,
    paddingBottom: 80,
  },

  title: {
    fontWeight: 900,
    fontSize: 34,
    marginBottom: theme.spacing.md,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,

    [theme.fn.smallerThan("sm")]: {
      fontSize: 32,
    },
  },

  control: {
    [theme.fn.smallerThan("sm")]: {
      width: "100%",
    },
  },

  mobileImage: {
    [theme.fn.largerThan("sm")]: {
      display: "none",
    },
  },

  desktopImage: {
    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },
}));

const errors = [
  {
    code: 400,
    title: "OOPS!",
    image: badRequest,
    content: "The server doesn't support the operation you requested",
  },
  {
    code: 401,
    title: "Something is not right...",
    image: unauthorized,
    content:
      "            Page you are trying to open does not exist. You may have mistyped the address, or the page has been moved to another URL. If you think this is an error contact support.",
  },
  {
    code: 403,
    title: "Something is not right...",
    image: forbidden,
    content:
      "            Page you are trying to open does not exist. You may have mistyped the address, or the page has been moved to another URL. If you think this is an error contact support.",
  },
  {
    code: 404,
    title: "Something is not right...",
    image: notFound,
    content:
      "            Page you are trying to open does not exist. You may have mistyped the address, or the page has been moved to another URL. If you think this is an error contact support.",
  },
  {
    code: 429,
    title: "Something is not right...",
    image: tooManyRequest,
    content:
      "            Page you are trying to open does not exist. You may have mistyped the address, or the page has been moved to another URL. If you think this is an error contact support.",
  },
  {
    code: 500,
    title: "Something is not right...",
    image: internalServerError,
    content:
      "            Page you are trying to open does not exist. You may have mistyped the address, or the page has been moved to another URL. If you think this is an error contact support.",
  },
  {
    code: 503,
    title: "Something is not right...",
    image: serviceUnavailable,
    content:
      "            Page you are trying to open does not exist. You may have mistyped the address, or the page has been moved to another URL. If you think this is an error contact support.",
  },
  {
    code: 504,
    title: "Something is not right...",
    image: gatewayTimeout,
    content:
      "            Page you are trying to open does not exist. You may have mistyped the address, or the page has been moved to another URL. If you think this is an error contact support.",
  },
];
export function ErrorPage({ errorCode }: { errorCode: any }) {
  const { classes } = useStyles();
  const navigate = useNavigate();

  const error =
    errors.find((err) => err.code === errorCode) ||
    errors.find((err) => err.code === 500);

  return (
    <Container size="xl" className={classes.root}>
      <SimpleGrid
        spacing={80}
        cols={2}
        breakpoints={[{ maxWidth: "sm", cols: 1, spacing: 40 }]}
      >
        <Image src={error?.image} className={classes.mobileImage} />
        <div>
          <Title className={classes.title}>{error?.title}</Title>
          <Text color="dimmed" size="lg">
            {error?.content}
          </Text>
          <Button
            variant="outline"
            size="md"
            mt="xl"
            className={classes.control}
            onClick={() => navigate("/", { replace: true })}
          >
            Get back to home page
          </Button>
        </div>
        <Image src={error?.image} className={classes.desktopImage} />
      </SimpleGrid>
    </Container>
  );
}
