import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppDispatch } from "../../app/store";
import FullPageLoader from "../../components/Loaders/FullPageLoader";
import { usePermify } from "../../components/PermissionComponent";

export default function Logout() {
  let navigate = useNavigate();
  const { setUser } = usePermify();
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    onLogout();
  }, [dispatch]);

  async function onLogout() {
    await localStorage.setItem(
      "auth",
      JSON.stringify({ isAuthenticated: false })
    );
    await setUser({
      id: "res.data.id",
      roles: [],
      permissions: [],
    });
    navigate("/", { replace: true });
  }

  return (
    <>
      <FullPageLoader text="" />
    </>
  );
}
