import { appSlice } from "./appSlice";

export const searchSlice = appSlice.injectEndpoints({
  endpoints: (builder) => ({
    search: builder.query({
      query: ({ query }) => `/search?query=${query}`,
      providesTags: ["Search"],
    }),
  }),
});

export const { useSearchQuery } = searchSlice;
