import React, { useState } from "react";
import {
  createStyles,
  Button,
  Group,
  Text,
  useMantineTheme,
  TextInput,
  Box,
  Image,
  PasswordInput,
  UnstyledButton,
  Modal,
  Container,
  Title,
  Anchor,
  Transition,
  Paper,
  Center,
  Card,
  Tabs,
  Checkbox,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { showNotification, updateNotification } from "@mantine/notifications";
import { ArrowLeft, ArrowRight, InfoCircle, TickCircle } from "iconsax-react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { parsePhoneNumber } from "react-phone-number-input";
import { useMediaQuery } from "@mantine/hooks";
import Logo from "../../assets/logo/logo1.png";
import { usePermify } from "../../components/PermissionComponent";
import { VerifyMobileNumber } from "../../components/VerifyMobileNumber";
import { PasswordStrength } from "./PasswordStrength";
import { useRegisterMutation } from "./authSlice";
import LoginWithGoogle from "./LoginWithGoole";

let user = {};

const useStyles = createStyles((theme) => ({
  categoryBtn: {
    minWidth: "220px !important",
    padding: "8px",
    height: "250px ",
    display: "flex",
    flexDirection: "column",
    justifyContent: "end",

    backgroundColor: "rgba(254, 155, 138, 0.15) !important ",
    webkitTransition: "all 0.4s",
    transition: "all 0.4s",

    "&:hover": {
      background: "rgba(254, 155, 138, 0.4) !important",
      scale: "1.05",
    },
  },
  btnActive: {
    background: "rgba(254, 155, 138, 0.4) !important",
  },
  serviceIcon: {
    width: "50%",
    margin: "auto !important",

    path: {
      fill: "#F4816D",
    },
  },
}));

export const Register = () => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const [otpShow, setOtpShow] = useState(false);
  const [registeredUser, setRegisteredUser] = useState<any>({});
  const [regFormStep, setRegFormStep] = useState(1);
  const navigate = useNavigate();

  const [accountType, setAccountType] = useState<"client" | "serviceProvider">("client");
  const location = useLocation();
  const state: any = location.state;
  const background = location.state && state.background;
  const [opened, setOpened] = useState(
    location.pathname === "/sign-up" || location.pathname === "/admin/users/new"
  );

  const [register, registerResponse] = useRegisterMutation();

  const isMobile = useMediaQuery("(max-width: 600px)");
  const { setUser } = usePermify();
  const form: any = useForm<any>({
    validateInputOnChange: true,
    initialValues: {
      password: "",
      confirmPassword: "",
      email: "",
      firstname: "",
      lastname: "",
      phone: "",
      country: "",
      termsOfService: false,
    },
    validate: {
      firstname: (value) =>
        value.trim().length < 1 ? "Firstname required" : null,
      lastname: (value) =>
        value.trim().length < 1 ? "Lastname required" : null,
      email: (value) =>
        value.trim().length < 1
          ? "Email is required"
          : /^\S+@\S+$/.test(value)
            ? null
            : "Invalid Email",
      phone: (value) =>
        !value
          ? "Phone number required"
          : isPossiblePhoneNumber(value.toString()) === true
            ? null
            : "Not valid phone number",
      password: (value) =>
        value.toString().trim().length < 8
          ? "Password should have at least 8 characters"
          : /[$&+,:;=?@#|'<>.^*()%!-]/.test(value.toString().trim())
            ? null
            : "Password should contain special character",
      confirmPassword: (value, values) =>
        value.toString().trim().length < 8
          ? "Password should have at least 8 characters"
          : value.toString().trim() !== values.password
            ? "Password does not match"
            : null,
      termsOfService: (value: any) =>
        value === false ? "Please read and accept our terms of service" : null,
    },
  });

  const onRegister = async (userData: any) => {
    const authItem = JSON.parse(localStorage.getItem("auth") || "null");

    const role = authItem?.role?.name;

    showNotification({
      id: "register",
      loading: true,
      title: "Creating Account",
      message: "Creating your fitabo account ...",
      autoClose: false,
      disallowClose: true,
    });

    const phone = userData.phone;

    delete userData.termsOfService;

    user = {
      firstname: userData.firstname,
      createdByAdmin: role === "admin" ? true : false,
      lastname: userData.lastname,
      email: userData.email,
      phone: phone.indexOf("+") === 0 ? phone.slice(1) : phone,
      role: accountType,
      accountType: accountType,
      password: userData.password,
      country: parsePhoneNumber(phone || "")?.country,
    };

    console.log(user);
    

    await register(user)
      .unwrap()
      .then((res) => {
        updateNotification({
          id: "register",
          color: "teal",
          icon: <InfoCircle />,
          title: "Successful",
          message: "Account created successfuly",
          autoClose: 2000,
        });

        if (role !== "admin") {
          //save user permissions to permify
          setUser({
            id: res?.id,
            roles: [res?.role?.name],
            permissions: res?.role?.permissions?.map((p: any) => p.name),
          });
          setRegisteredUser(res);
        }

        if (location.pathname === "/sign-up") {
          setRegFormStep(1 + regFormStep);
        }
        if (location.pathname !== "/sign-up") {
          setOpened(false);
          navigate(-1);
        }
      })
      .catch((err) => {
        updateNotification({
          id: "register",
          color: "red",
          title: "Failed to Register",
          icon: <InfoCircle />,
          message: err?.data?.info || "",
          autoClose: 2000,
        });
      });
  };

  function authenticateUesr() {
    setOpened(false);
    navigate(state.to || "/", { replace: true });
  }

  return (
    <Modal
      onClose={() => {
        setOpened(false);
        navigate(state.to || "/", { replace: true });
      }}
      opened={opened}
      size={"xl"}
      radius="lg"
      centered
      title={
        <Image src={Logo} alt="wearefitabo.com" ml={4} my={12} width={150} />
      }
      transition="rotate-left"
      transitionDuration={300}
      transitionTimingFunction="ease"
      overflow="inside"
      overlayColor={
        theme.colorScheme === "dark"
          ? theme.colors.dark[9]
          : theme.colors.gray[2]
      }
      overlayOpacity={0.55}
      overlayBlur={3}
      withCloseButton={true}
    >
      <Container px={"sm"} style={{ minHeight: "400px", overflow: "hidden" }}>
        <Tabs pb={24} unstyled value={regFormStep.toString()}>
          <Tabs.Panel value={"1"}>
            {/* // choose account */}
            <Transition
              mounted={regFormStep === 1}
              transition={"slide-right"}
              duration={300}
              timingFunction="ease"
            >
              {(styles) => (
                <Box style={{ ...styles }}>
                  <Text weight={500} size="xl" my={"xl"} align="center">
                    {" "}
                    Join as a client or service provider
                  </Text>
                  <Box py={18} />
                  <Group my={"xl"} position="center" spacing={"xl"}>
                    <UnstyledButton
                      onClick={() => {
                        setAccountType("client");
                      }}
                      style={{ maxWidth: "300px" }}
                    >
                      <Card
                        className={classes.categoryBtn}
                        shadow={"sm"}
                        radius="lg"
                      >
                        {accountType === "client" && (
                          <Group position="right">
                            <TickCircle
                              color="#FF8A65"
                              size={32}
                              style={{
                                float: "right",
                                position: "absolute",
                                top: 0,
                                marginTop: "24px",
                              }}
                            />
                          </Group>
                        )}
                        <Text
                          weight={500}
                          size="lg"
                          mb="sm"
                          align="center"
                          color={theme.primaryColor}
                        >
                          Client
                        </Text>
                        <Text
                          size="sm"
                          weight={300}
                          align="center"
                          style={{ margin: "0px", letterSpacing: 1 }}
                        >
                          Looking for a beauty point, massage/SPA service,
                          fitness session, wellness, or sports activities
                        </Text>
                      </Card>
                    </UnstyledButton>
                    <UnstyledButton
                      onClick={() => {
                        setAccountType("serviceProvider");
                      }}
                      style={{ maxWidth: "300px" }}
                    >
                      <Card
                        className={classes.categoryBtn}
                        shadow={"sm"}
                        radius="lg"
                      >
                        {accountType === "serviceProvider" && (
                          <Group position="right" style={{}}>
                            <TickCircle
                              color="#FF8A65"
                              size={32}
                              style={{
                                float: "right",
                                position: "absolute",
                                top: 0,
                                marginTop: "24px",
                              }}
                            />
                          </Group>
                        )}
                        <Text
                          weight={500}
                          size="lg"
                          mb="sm"
                          align="center"
                          color={theme.primaryColor}
                        >
                          Service provider
                        </Text>
                        <Text
                          size={"sm"}
                          weight={300}
                          align="center"
                          style={{ margin: "0px", letterSpacing: 1 }}
                        >
                          I own GYM, beauty point, massage/SPA, physiotherapy
                          point, I can provide a fitness session or any sports
                          activities
                        </Text>
                      </Card>
                    </UnstyledButton>
                  </Group>

                  <Center>
                    <Button
                      size="lg"
                      radius={"md"}
                      mt={24}
                      style={{ width: "200px" }}
                      rightIcon={<ArrowRight />}
                      disabled={accountType.trim().length < 1}
                      loading={registerResponse?.isLoading}
                      onClick={() => {
                        setRegFormStep(1 + regFormStep);
                      }}
                    >
                      Next
                    </Button>
                  </Center>
                  <Group position="center" mt={24} align={"middle"}>
                    <Text>Already have an account?</Text>
                    <Anchor
                      component={Link}
                      to="/sign-in"
                      state={{ background: background }}
                      replace
                    >
                      Sign in
                    </Anchor>
                  </Group>
                </Box>
              )}
            </Transition>
            {/* // choose account */}
          </Tabs.Panel>

          <Tabs.Panel value={"2"}>
            {/* // create account */}
            <Transition
              mounted={regFormStep === 2}
              transition={"slide-left"}
              duration={300}
              timingFunction="ease"
            >
              {(styles) => (
                <Box style={{ ...styles }}>
                  <Title order={2} mt={"xl"} align="center">
                    Create an account
                  </Title>
                  <Text align="center" size={"sm"} mb={"xl"} color={"dimmed"}>
                    Join Fitabo! Please enter your details.
                  </Text>
                  <Box py={8} />
                  <Center>
                    <Paper
                      m={0}
                      p={0}
                      style={{
                        opacity: otpShow ? 0 : 1,
                        width: isMobile ? "100%" : "90%",
                      }}
                    >
                      <form
                        onSubmit={form.onSubmit((values: any) => {
                          !form.validate().hasErrors && onRegister(values);
                        })}
                      >
                        <Group position="apart" align="start" grow>
                          <TextInput
                            label="Firstname"
                            placeholder="John"
                            my={12}
                            radius={"md"}
                            variant="filled"
                            {...form.getInputProps("firstname")}
                          />
                          <TextInput
                            label="Lastname"
                            placeholder="Doe"
                            my={12}
                            radius={"md"}
                            variant="filled"
                            {...form.getInputProps("lastname")}
                          />
                        </Group>

                        <TextInput
                          label="Email"
                          my={12}
                          radius={"md"}
                          variant="filled"
                          placeholder="example@mail.com"
                          {...form.getInputProps("email")}
                        />
                        <Box pt={16}>
                          Phone Number
                          <PhoneInput
                            international
                            countryCallingCodeEditable={false}
                            defaultCountry="TZ"
                            my={12}
                            radius={"md"}
                            variant="filled"
                            {...form.getInputProps("phone")}
                          />
                          <Transition
                            mounted={form.errors.phone !== undefined}
                            transition={"fade"}
                            duration={300}
                            timingFunction="ease"
                          >
                            {(styles) => (
                              <Box
                                style={{
                                  ...styles,
                                  color: "#fa5252",
                                  top: 0,
                                  fontSize: "12px",
                                  height: "auto",
                                }}
                              >
                                {form.errors.phone}
                              </Box>
                            )}
                          </Transition>
                        </Box>
                        <Group position="apart" align="start" grow>
                          <PasswordStrength
                            inputName="password"
                            label="Password"
                            form={form}
                          />
                          <PasswordInput
                            placeholder="Confirm password"
                            my={12}
                            radius={"md"}
                            variant="filled"
                            label="Confirm Password"
                            {...form.getInputProps("confirmPassword")}
                          />
                        </Group>
                        <Checkbox
                          {...form.getInputProps("termsOfService", {
                            type: "checkbox",
                          })}
                          mt={24}
                          mb={12}
                          label={
                            <>
                              Yes, I understand and agree to the {"  "}
                              <Anchor
                                component={Link}
                                to="/policies"
                                target="_blank"
                                state={{ background: background }}
                                replace
                              >
                                Fitabo Terms of service
                              </Anchor>
                            </>
                          }
                        />
                        <Transition
                          mounted={form.errors.termsOfService !== undefined}
                          transition={"fade"}
                          duration={300}
                          timingFunction="ease"
                        >
                          {(styles) => (
                            <Box
                              style={{
                                ...styles,
                                color: "#fa5252",
                                top: 0,
                                fontSize: "12px",
                                height: "auto",
                              }}
                            >
                              {form.errors.termsOfService}
                            </Box>
                          )}
                        </Transition>

                        <Group position="center" spacing={"lg"}>
                          <Button
                            leftIcon={<ArrowLeft />}
                            color="dark"
                            size="lg"
                            radius={"md"}
                            mt={24}
                            disabled={registerResponse?.isLoading}
                            style={{ width: "200px" }}
                            onClick={() => {
                              setRegFormStep(regFormStep - 1);
                            }}
                          >
                            Back
                          </Button>
                          <Button
                            size="lg"
                            radius={"md"}
                            mt={24}
                            loading={registerResponse?.isLoading}
                            style={{ width: "200px" }}
                            type="submit"
                            disabled={!form.isValid()}
                          >
                            Create Account
                          </Button>
                        </Group>
                        <LoginWithGoogle accountType={accountType} />
                      </form>
                    </Paper>
                  </Center>
                  <Group position="center" mt={24} align={"middle"}>
                    <Text>Already have an account?</Text>
                    <Anchor
                      component={Link}
                      to="/sign-in"
                      state={{ background: background }}
                      replace
                    >
                      Sign in
                    </Anchor>
                  </Group>
                </Box>
              )}
            </Transition>
          </Tabs.Panel>

          {/* // verify phone */}
          {location.pathname === "/sign-up" && (
            <Tabs.Panel value={"3"}>
              <Transition
                mounted={regFormStep === 3}
                transition={"slide-left"}
                duration={300}
                timingFunction="ease"
              >
                {(styles) => (
                  <Box style={styles}>
                    <VerifyMobileNumber
                      showAsModal={false}
                      user={registeredUser}
                      onVerified={() => authenticateUesr()}
                    />
                  </Box>
                )}
              </Transition>
            </Tabs.Panel>
          )}
          {/* // create account */}
        </Tabs>
      </Container>
    </Modal>
  );
};
