import axios from "axios";

export function api() {
  return axios.create({
    baseURL:
      process.env.NODE_ENV === "development"
        ? "http://localhost:2500"
        : "https://www.wearefitabo.com/dev/api",
    // baseURL: "http://localhost:2500",
    headers: {
      "Content-type": "application/json",
      Authorization:
        JSON.parse(localStorage.getItem("auth") || "null")?.token || "null",
    },
  });
}

// export const api = apiClient();
