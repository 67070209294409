import { Button } from "@mantine/core";
import { Google } from "iconsax-react";
import getServerUrl from "../../utils/helpers/getServerUrl";

function LoginWithGoogle({
  accountType,
}: {
  accountType?: "client" | "serviceProvider";
}) {
  function navigateToOauth() {
    try {
      const endpoint = "/auth/google";
      const serverUrl = getServerUrl();

      window.location.href = accountType
        ? `${serverUrl}${endpoint}?accountType=${accountType}`
        : `${serverUrl}${endpoint}`;
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Button
      fullWidth
      color="gray"
      size="lg"
      radius={"md"}
      mt={24}
      type="button"
      onClick={navigateToOauth}
      // loading={loginResponse.isLoading}
    >
      <Google style={{ marginRight: 10 }} />
      <span>Continue With Google</span>
    </Button>
  );
}

export default LoginWithGoogle;
