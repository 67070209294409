import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "./apiClient";

export interface BannerInterface {
  _id: string;
  name: string;
  description: string;
  permissions: [];
  createdAt: string;
  updatedAt: string;
}

export interface BannersResult {
  // pageLinks: Links | null
  pageCount: number;
  // banners: any[]
  banners: BannerInterface[];
}

// get banners
export const getBanners = createAsyncThunk(
  "banners/getBanners",
  async (body: any, { rejectWithValue }) => {
    try {
      const response = await api().get("/banners");
      if (response.status === 200) {
        return response.data;
      } else {
        return rejectWithValue(response.data.info);
      }
    } catch (e) {
      const {
        response: { data, status },
      } = e as unknown as {
        response: { data: any; status: number };
      };

      throw {
        name: "Request Failed",
        message: data?.info || "",
        code: `${status}`,
      };
    }
  }
);

export const addBanner = createAsyncThunk(
  "banners/addBanner",
  async (body: any, { rejectWithValue }) => {
    try {
      const response = await api().post("/banners", body);
      if (response.status === 200) {
        return response.data;
      } else {
        return rejectWithValue(response.data.info);
      }
    } catch (e) {
      const {
        response: { data, status },
      } = e as unknown as {
        response: { data: any; status: number };
      };

      throw {
        name: "Request Failed",
        message: data?.info || "",
        code: `${status}`,
      };
    }
  }
);

export const updateBanner = createAsyncThunk(
  "banners/updateBanner",
  async (body: any, { rejectWithValue }) => {
    try {
      const id = body._id;
      delete body._id;
      delete body.image;
      delete body.deleted;
      delete body.createdAt;
      delete body.updatedAt;
      delete body.__v;
      const response = await api().patch(`/banners/${id}`, body);
      if (response.status === 200) {
        return response.data;
      } else {
        return rejectWithValue(response.data.info);
      }
    } catch (e) {
      const {
        response: { data, status },
      } = e as unknown as {
        response: { data: any; status: number };
      };

      throw {
        name: "Request Failed",
        message: data?.info || "",
        code: `${status}`,
      };
    }
  }
);

export const deleteBanner = createAsyncThunk(
  "banners/deleteBanner",
  async (body: any, { rejectWithValue }) => {
    try {
      const id = body._id;
      const response = await api().delete(`/banners/${id}`, body);
      if (response.status === 200) {
        response.data["id"] = body._id;
        return response.data;
      } else {
        return rejectWithValue(response.data.info);
      }
    } catch (e) {
      const {
        response: { data, status },
      } = e as unknown as {
        response: { data: any; status: number };
      };

      throw {
        name: "Request Failed",
        message: data?.info || "",
        code: `${status}`,
      };
    }
  }
);
