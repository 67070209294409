import * as React from 'react';
import { useEffect, useState, useContext } from 'react'
import { showNotification } from "@mantine/notifications";

export interface UserPayload {
  id: string,
  roles: string[],
  permissions: string[]
}

const LOCAL_STORAGE_KEY_USER = "__permifyUser";

export interface HasAccessProps {
  roles?: string[],
  permissions?: string[]
  isLoading?: React.ReactElement,
  renderAuthFailed?: any,
  onAuthFailed?: ()=>void,
  // children: any
  children: React.ReactChild
}

const HasAccess: React.FunctionComponent<HasAccessProps> = ({
  roles,
  permissions,
  isLoading,
  renderAuthFailed,
  onAuthFailed,
  children
}) => {
  const [hasAccess, setHasAccess] = useState(false)
  const [checking, setChecking] = useState(false)



  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY_USER) || '{}');

    if(!storedUser) {
      console.log('No user provided to Permify! You should set user to perfom access check') 
      return;
    } 

    setChecking(true)

    // role check
    if (roles && storedUser.roles && storedUser.roles.length > 0) {
      const intersection = storedUser.roles.filter((role: string) => roles.includes(role));
      if (intersection.length > 0) setHasAccess(true)
    
      if (intersection.length < 1 && !checking && onAuthFailed) {
        return onAuthFailed
      } 
    }

    // permission check
    if (permissions && storedUser.permissions && storedUser.permissions.length > 0) {
      const intersection = storedUser.permissions.filter((permission: string) => permissions.includes(permission));
      if (intersection.length > 0) setHasAccess(true)
   
      if (intersection.length < 1 && !checking && onAuthFailed) {
        return onAuthFailed
      }
     }
    setChecking(false)

  }, [roles, permissions])

  if (!hasAccess && checking) {
    return isLoading
  }
  
  if (hasAccess) {
    return (
      <React.Fragment>
        {children}
      </React.Fragment>
    )
  }
  
 
  if (renderAuthFailed) {
    return renderAuthFailed
  }

  return null
}

export default HasAccess;
