import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../api/apiClient";
import {
  getBanners,
  addBanner,
  updateBanner,
  deleteBanner,
} from "../../api/bannersApi";

const initialState = {
  banners: [] as any,
  status: "idle",
  isLoading: false,
  error: null as any,
};

const bannerSlice = createSlice({
  name: "banners",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getBanners.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getBanners.fulfilled, (state, action) => {
        state.isLoading = false;
        state.banners = action.payload;
      })
      .addCase(getBanners.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "";
      })
      .addCase(addBanner.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(addBanner.fulfilled, (state, action) => {
        state.isLoading = false;
        state.banners.push(action.payload.banner);
      })
      .addCase(addBanner.rejected, (state, action) => {
        state.isLoading = false;

        state.error = action.error.message || "";
      })
      .addCase(updateBanner.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateBanner.fulfilled, (state, action) => {
        state.isLoading = false;
        const { _id, title, intro, content, updatedAt } = action.payload.banner;
        const existingBanner = state.banners.find(
          (banner: any) => banner._id === _id
        );
        if (existingBanner) {
          existingBanner.title = title;
          existingBanner.content = content;
          existingBanner.intro = intro;
          existingBanner.updatedAt = updatedAt;
        }
      })
      .addCase(updateBanner.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "";
      })
      .addCase(deleteBanner.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteBanner.fulfilled, (state, action) => {
        state.isLoading = false;

        let newBannersData = state.banners.filter((banner: any) => {
          return action.payload.id !== banner._id;
        });
        state.banners = newBannersData;
      })
      .addCase(deleteBanner.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "";
      });
  },
});

// export const { bannerAdded } = bannerSlice.actions
export default bannerSlice.reducer;

export const selectAllBanners = (state: any) => state.banners.banners;

export const selectBannerById = (state: any, bannerId: string) =>
  state.banners.banners.find((banner: any) => banner._id === bannerId);
