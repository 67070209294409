import { openConfirmModal } from "@mantine/modals";
import {
    Text,
  } from "@mantine/core";

export const opencustomConfirmModal = (title:any, desc:any, onConfirm: ()=>void) => openConfirmModal({
    title: title,
    centered: true,
     children: (
      <Text size="sm">
      {desc}
      </Text>
    ),
    labels: { confirm: 'Confirm', cancel: 'Cancel' },
    onCancel: () => {},
    onConfirm: () => onConfirm(),
  });