import React, { useState } from "react";

import { Outlet, Link } from "react-router-dom";
import "./layout.css";
import {
  ActionIcon,
  Affix,
  AppShell,
  Burger,
  createStyles,
  Group,
  Navbar,
  ScrollArea,
  Image,
  Transition,
  useMantineTheme,
  Box,
} from "@mantine/core";
import { HeaderSimple } from "./HeaderN";
import { LinksGroup } from "./NavbarLinksGroup";
import {
  ArrowUp2,
  Blogger,
  Calendar,
  CalendarSearch,
  Category2,
  DeviceMessage,
  Home2,
  Logout,
  Profile2User,
  Stickynote,
  User,
} from "iconsax-react";
import { useMediaQuery, useWindowScroll } from "@mantine/hooks";
import { BottomNav } from "./BottomNav";
import Logo from "../../assets/logo/logo1.png";
import { useSelector } from "react-redux";
import { FooterSection } from "./Footer";

const useStyles = createStyles((theme) => ({
  navbar: {
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.white,
    paddingBottom: 0,
  },

  links: {
    marginLeft: -theme.spacing.md,
    marginRight: -theme.spacing.md,
  },
  link: {
    ...theme.fn.focusStyles(),
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    fontSize: theme.fontSizes.sm,
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[1]
        : theme.colors.gray[7],
    padding: `${theme.spacing.md}px ${theme.spacing.sm}px`,
    borderRadius: theme.radius.sm,
    fontWeight: 500,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
      color: theme.colorScheme === "dark" ? theme.white : theme.black,
    },
  },
  linkIcon: {
    marginRight: theme.spacing.sm,
  },
  linksInner: {
    paddingTop: theme.spacing.xl,
    paddingBottom: theme.spacing.xl,
  },

  footer: {
    marginLeft: theme.spacing.md,
    marginRight: theme.spacing.md,
    borderTop: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },
}));

const pages = [
  { label: "Dashboard", accountType: ["admin"], icon: Home2, link: "/admin" },
  {
    label: "Banners",
    accountType: ["admin"],
    icon: DeviceMessage,
    link: "/admin/banners",
  },
  {
    label: "Blogs",
    accountType: ["admin"],
    icon: Blogger,
    link: "/admin/blogs",
  },
  {
    label: "Bookings",
    accountType: ["admin"],
    icon: CalendarSearch,
    links: [
      {
        label: "Calendar",
        accountType: ["admin"],
        link: "/admin/bookings/calendar",
      },
      {
        label: "Booking List",
        accountType: ["admin"],
        link: "/admin/bookings",
      },
    ],
  },
  {
    label: "Services",
    accountType: ["admin"],
    icon: Category2,
    links: [
      {
        label: "Categories",
        accountType: ["admin"],
        link: "/admin/services/categories",
      },
      {
        label: "Service List",
        accountType: ["admin"],
        link: "/admin/services",
      },
      {
        label: "Service Providers",
        accountType: ["admin"],
        link: "/admin/services/providers",
      },
    ],
  },
  {
    label: "User Management",
    accountType: ["admin"],
    icon: Profile2User,
    links: [
      { label: "Users", accountType: ["admin"], link: "/admin/users" },
      // {
      //   label: "Blocked Users",
      //   accountType: ["admin"],
      //   link: "/admin/users/blocked",
      // },
      {
        label: "Roles and Permissions",
        accountType: ["admin"],
        link: "/admin/users/roles",
      },
    ],
  },
  {
    label: "Policies and FAQs",
    accountType: ["admin"],
    icon: Stickynote,
    links: [
      { label: "FAQs", accountType: ["admin"], link: "/admin/faqs" },
      {
        label: "Policies",
        accountType: ["admin"],
        link: "/admin/policies",
      },
      {
        label: "SoPs",
        accountType: ["admin"],
        link: "/admin/sops",
      },
    ],
  },

  {
    label: "Home",
    accountType: ["serviceProvider", "client"],
    icon: Home2,
    link: "/",
  },
  {
    label: "My Bookings",
    accountType: ["serviceProvider", "client"],
    icon: Calendar,
    link: "/bookings",
  },
  {
    label: "My Services",
    accountType: ["serviceProvider"],
    icon: Blogger,
    link: "/myservices",
  },
  {
    label: "Profile",
    accountType: ["admin", "client", "serviceProvider"],
    icon: User,
    link: "/profile",
  },
];

export default function Layout() {
  const auth = JSON.parse(localStorage.getItem("auth") || "null");
  const isMobile = useMediaQuery("(max-width: 755px");
  const theme = useMantineTheme();
  const [openSideBar, setOpenSideBar] = useState(
    auth?.isAuthenticated && auth?.accountType === "admin" ? true : false
  );
  const { classes } = useStyles();

  const links = pages.map((item: any) => (
    <LinksGroup {...item} key={item.label} />
  ));

  const [scroll, scrollTo] = useWindowScroll();

  return (
    <AppShell
      styles={{
        main: {
          padding: 0,
          background:
            theme.colorScheme === "dark"
              ? theme.colors.dark[7]
              : theme.colors.gray[0],
        },
      }}
      header={
        <HeaderSimple
          openSideBar={openSideBar}
          onOpenSideBar={() => {
            setOpenSideBar(!openSideBar);
          }}
          closeSideBar={() => setOpenSideBar(openSideBar ? false : true)}
        />
      }
      footer={<FooterSection />}
    >
      {/* <div style={{ flex: "1 1 auto", margin: 0, padding: "0px !important" }}> */}
      {/* <NoInternetConnection> */}
      <Box
        px={"0"}
        pb={96}
        pt={180}
        style={{
          height: "100%",
          background:
            theme.colorScheme === "dark"
              ? theme.colors.dark[7]
              : theme.colors.gray[0],
        }}
      >
        <Outlet />
      </Box>
      {/* </NoInternetConnection> */}
      {/* </div> */}
      {/* <FullscreenMenu
        openSideBar={auth?.role?.name !== "admin" && openSideBar}
        setOpenSideBar={setOpenSideBar}
      /> */}
      <BottomNav
        opened={isMobile && openSideBar}
        closeSideBar={() => setOpenSideBar(!openSideBar)}
      />
      <Affix position={{ bottom: 80, right: 20 }}>
        <Transition transition="slide-up" mounted={scroll.y > 0}>
          {(transitionStyles) => (
            <ActionIcon
              variant="filled"
              color={"primary"}
              radius={"xl"}
              size="xl"
              style={transitionStyles}
              onClick={() => scrollTo({ y: 0 })}
            >
              <ArrowUp2 size="28" variant="Outline" />
            </ActionIcon>
          )}
        </Transition>
      </Affix>
    </AppShell>
  );
}
