import React, { useEffect } from "react";
import {
  Container,
  Button,
  Group,
  Modal,
  useMantineTheme,
  TextInput,
  Box,
  Image,
  NumberInput,
  Text,
  Select,
  SimpleGrid,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { upperFirst, useMediaQuery } from "@mantine/hooks";
import RichTextEditor from "@mantine/rte";
import UploadImage from "../../components/UploadImage/UploadImage";

interface ServiceEditFormProps {
  title: string;
  fields: any[];
  initialValues: any;
  onSubmit(values: any): void;
  openForm: boolean;
  closeForm: () => void;
}

export function ServiceForm({
  fields,
  initialValues,
  title,
  onSubmit,
  openForm,
  closeForm,
}: ServiceEditFormProps) {
  const isMobile = useMediaQuery("(max-width: 755px");
  const theme = useMantineTheme();

  const form = useForm({
    validateInputOnBlur: true,
    initialValues,
    validate: {
      name: (value) =>
        fields.includes("name")
          ? value.toString().trim().length < 1
            ? "Name is required"
            : null
          : null,
      description: (value) =>
        fields.includes("description")
          ? value.trim().length < 1
            ? "Description is required"
            : null
          : null,
      image: (value) =>
        fields.includes("image")
          ? value?.toString().trim().length > 1
            ? null
            : "Image is required"
          : null,
      category: (value) =>
        fields.includes("category")
          ? value.trim().length < 1
            ? "Category is required"
            : null
          : null,
      duration: (value) =>
        fields.includes("duration")
          ? value < 1
            ? "Duration is required"
            : null
          : null,
    },
  });
  useEffect(() => {
    form.setValues(initialValues);
  }, [initialValues]);

  return (
    <Modal
      onClose={() => closeForm()}
      opened={openForm}
      closeOnClickOutside={false}
      centered
      transition="slide-down"
      transitionDuration={500}
      transitionTimingFunction="ease"
      size={"xl"}
      radius="lg"
      overflow="inside"
      title={title}
      overlayColor={
        theme.colorScheme === "dark"
          ? theme.colors.dark[9]
          : theme.colors.gray[2]
      }
      overlayOpacity={0.55}
      overlayBlur={3}
      fullScreen={isMobile}
      style={{ height: isMobile ? "85%" : "100%" }}
    >
      <Container>
        <Box pt={24}>
          <form
            onSubmit={(e: any) => {
              e.preventDefault();
              if (!form.validate().hasErrors) {
                onSubmit(form.values);
              }
            }}
          >
            {fields.map((field: any, index: any) =>
              field === "image" ? (
                <SimpleGrid
                  key={index}
                  cols={2}
                  breakpoints={[{ maxWidth: "sm", cols: 1 }]}
                  my="xl"
                >
                  <div>
                    <UploadImage
                      parentFolder="services"
                      onUploadComplete={(value) => {
                        form.setFieldValue("image", value);
                      }}
                    />
                    <Text align="center" size={"sm"} color="red">
                      {form.errors.image}
                    </Text>
                  </div>
                  {form.values?.image && (
                    <Image
                      src={
                        form?.values?.image?.includes("https://")
                          ? form?.values?.image
                          : `https://res.cloudinary.com/dw4t6ta7k/image/upload/c_thumb,w_600,h_600,g_face/${form?.values?.image}.webp`
                      }
                    />
                  )}
                </SimpleGrid>
              ) : field === "duration" ? (
                <NumberInput
                  key={index}
                  my={12}
                  variant="filled"
                  radius={"md"}
                  size="md"
                  label={`${upperFirst(field)} (minutes)`}
                  {...form.getInputProps(field)}
                />
              ) : field === "description" ? (
                <Box key={index}>
                  <Text>Description</Text>
                  <RichTextEditor
                    sticky
                    controls={[
                      [
                        "bold",
                        "italic",
                        "underline",
                        "strike",
                        "clean",
                        "highlight",
                      ],
                      ["h1", "h2", "h3", "h4"],
                      ["alignLeft", "alignCenter", "alignRight"],
                      [
                        "blockquote",
                        "column",
                        "unorderedList",
                        "orderedList",
                        "sup",
                        "sub",
                      ],
                      ["link", "image"],
                    ]}
                    {...form.getInputProps(field)}
                    style={{
                      maxWidth:
                        window.self.innerWidth < 780
                          ? window.self.innerWidth * 0.85
                          : window.self.innerWidth > 1320
                          ? 1320 * 0.9
                          : window.self.innerWidth * 0.9,
                    }}
                  />
                  <Text size={"sm"} color="red" pt={8}>
                    {" "}
                    {form.errors.description}
                  </Text>
                </Box>
              ) : field === "category" ? (
                <Group key={index} align="end" noWrap>
                  <Select
                    searchable
                    my={12}
                    variant="filled"
                    radius={"md"}
                    size="md"
                    label={upperFirst(field)}
                    placeholder="Pick one"
                    data={initialValues.categoriesData || []}
                    {...form.getInputProps(field)}
                    style={{ width: "100%" }}
                  />
                </Group>
              ) : (
                <TextInput
                  key={index}
                  my={12}
                  variant="filled"
                  radius={"md"}
                  size="md"
                  label={upperFirst(field)}
                  {...form.getInputProps(field)}
                />
              )
            )}

            <Group position="right" style={{ marginTop: 15 }}>
              <Button
                size="md"
                color={"dark"}
                variant="subtle"
                radius={"md"}
                mt={24}
                onClick={() => {
                  closeForm();
                  form.reset();
                }}
              >
                Cancel
              </Button>
              <Button
                size="md"
                radius={"md"}
                mt={24}
                type="submit"
                disabled={!form.isValid()}
              >
                Save
              </Button>
            </Group>
          </form>
        </Box>
      </Container>
    </Modal>
  );
}
