import { useState } from "react";
import {
  Image,
  Container,
  Title,
  Button,
  Group,
  Text,
  Modal,
  useMantineTheme,
  TextInput,
  Box,
  PasswordInput,
  Anchor,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { showNotification, updateNotification } from "@mantine/notifications";
import { InfoCircle } from "iconsax-react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useMediaQuery } from "@mantine/hooks";
import Logo from "../../assets/logo/logo1.png";
import { useSetNewPasswordMutation } from "./authSlice";
// import {SecureLS} from ""

// var ls = new SecureLS();

export function ResetPassword() {
  const navigate = useNavigate();
  const theme = useMantineTheme();
  const location = useLocation();
  const state: any = location.state;
  const background = location.state && state.background;
  const [setNewPassword , setNewPasswordResponse] = useSetNewPasswordMutation()
  const [opened, setOpened] = useState(location.pathname === "/sign-in");
  const isMobile = useMediaQuery("(max-width: 600px)");
  const {token} = useParams()

  const onsetNewPassword = async (values: any) => {
    showNotification({
      id: "setNewPassword",
      loading: true,
      title: "Reseting",
      message: "Please Wait  Reseting......",
      autoClose: false,
      disallowClose: true,
    });

    await setNewPassword(values)
      .unwrap()
      .then(async (res) => {
        console.log(res)
        updateNotification({
          id: "setNewPassword",
          color: "teal",
          title: "Successful",
          icon: <InfoCircle />,
          message: "Password Reseted Successfully",
          autoClose: 1000,
          onClose:(()=>{navigate('/')})
        });
      })
      .catch((err) => {
        updateNotification({
          id: "setNewPassword",
          color: "red",
          title: "Password Not Reset",
          icon: <InfoCircle />,
          message: err?.data?.info || "",
          autoClose: 2000,
        });
      });
  };

  const form = useForm({
    validateInputOnBlur: true,
    initialValues: {
      password: "",
      verifyPassword: "",
    },
    validate: {
      password: (value) =>
        value.toString().trim().length < 8
          ? "Password should have at least 8 letters"
          : /[$&+,:;=?@#|'<>.^*()%!-]/.test(value.toString().trim())
          ? null
          : "Password should contain special character",
      verifyPassword: (value, values) =>
          value.toString().trim().length < 8
          ? "Password should have at least 8 characters"
          : value.toString().trim() !== values.password
          ? "Password does not match"
          : null,
    },
  });

  const auth = JSON.parse(localStorage.getItem("auth") || "null");

  async function loginMethod(e: any) {
    e.preventDefault();
    if (!form.validate().hasErrors) {
      // onLogin(form.values)
    }
  }

  return (
    <Modal
      onClose={() => navigate("/")}
      opened={true}
      centered
      transition="rotate-left"
      transitionDuration={300}
      transitionTimingFunction="ease"
      overlayColor={
        theme.colorScheme === "dark"
          ? theme.colors.dark[9]
          : theme.colors.gray[2]
      }
      overlayOpacity={0.55}
      overlayBlur={3}
      fullScreen={isMobile}
      styles={() =>
        isMobile
          ? {
              root: {
                height: "80%",
                top: "100px",
              },
            }
          : {}
      }
    >
      <Container pb={24}>
        <Image
          src={Logo}
          alt="wearefitabo.com"
          mx={"auto"}
          my={24}
          width={200}
        />

        <Title my={12} align="center" order={2}>
          Reset Your Password
        </Title>
        <Text align="center" color={"dimmed"}>
          Please choose a strong password
        </Text>
        <Box pt={24}>
          <form style={{ width: "100%" }} onSubmit={(e)=>{
            e.preventDefault()
            if(form.validate().hasErrors) return
            onsetNewPassword({...form.values,token:token})
          }}>
            <PasswordInput
              label="Password"
              placeholder="Enter a new password"
              {...form.getInputProps("password")}
            />
            <PasswordInput
              placeholder="Confirm Password"
              label="Confirm Password"
              my={12}
              {...form.getInputProps("verifyPassword")}
            />
            <Button
              fullWidth
              size="lg"
              radius={"md"}
              mt={24}
              type="submit"
              // loading={authstatus === "loading"}
            >
              Confirm
            </Button>
          </form>
        </Box>
      </Container>
    </Modal>
  );
}
