import {
  createStyles,
  Text,
  Center,
  Tabs,
  ScrollArea,
  Skeleton,
  Group,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetCategoriesQuery } from "../Categories/categoriesSlice";

const useStyles = createStyles((theme) => ({
  serviceIcon: {
    width: "32px",
    height: "32px",
    margin: "auto",
    [theme.fn.smallerThan("xs")]: {
      width: "24px",
      height: "24px",
    },
  },

  header: {
    borderBottom: 0,
    zIndex: 2,
    paddingBottom: "12px",
    width: "100%",
    overflow: "hidden",
    margin: "auto",
    background:
      theme.colorScheme === "dark"
        ? "rgba(0, 0, 0, 0.8)"
        : "rgba(255, 255, 255, 1)",
  },
}));

export function LandingNav() {
  const { classes, theme } = useStyles();
  const navigate = useNavigate();
  let { category }: any = useParams();

  const [selected, setSelected] = useState<any>("");
  const {
    data: categories,
    isLoading,
    isError,
    isSuccess,
    error,
  } = useGetCategoriesQuery("");

  if (category === undefined) {
    category = null;
  }

  return (
    <Center className={classes.header}>
      <ScrollArea type="never" offsetScrollbars scrollbarSize={4}>
        <Center pb={8}>
          <Tabs
            m={0}
            p={0}
            value={category}
            // value={selected || category || ""}
            onTabChange={(value) => {
              // if (!isLoading) {
              // setSelected(value);
              navigate(`/${value}`);
              // }
            }}
            styles={(theme) => ({
              tabsList: {
                border: "none",
                flexWrap: "nowrap",
                paddingRight: "24px",
              },
              tab: {
                color:
                  theme.colorScheme === "dark"
                    ? theme.colors.dark[0] + " !important"
                    : theme.colors.gray[6] + " !important",
                fontSize: theme.fontSizes.sm,
                fontWeight: 500,
                padding: `0px ${theme.spacing.lg}px`,
                border: "0",
                textAlign: "center",
                transform: "all",
                [theme.fn.smallerThan("xs")]: {
                  fontSize: theme.fontSizes.xs,
                  padding: `0px ${theme.spacing.sm}px`,
                },
                "&[data-active]": {
                  color: "#F4816D !important",
                  path: { fill: "#F4816D !important" },
                  borderBottom: "4px solid #F4816D",
                  borderRadius: "2px",
                  "&, stroke": {
                    path: {
                      fill: "none !important",
                      stroke: "#F4816D !important",
                    },
                  },
                },
              },
            })}
          >
            <Tabs.List>
              {isLoading || isError ? (
                <>
                  {Array(10)
                    .fill(1)
                    .map((item: any, index: any) => (
                      <Tabs.Tab key={index} value={index.toString()}>
                        <Group position="center">
                          <Skeleton height={28} width={28} mb="xs" />
                        </Group>
                        <Skeleton height={12} width={64} />
                      </Tabs.Tab>
                    ))}
                </>
              ) : (
                <>
                  {categories?.map((item: any, index: any) => (
                    <Tabs.Tab key={index} value={item.name}>
                      <div
                        className={classes.serviceIcon}
                        dangerouslySetInnerHTML={{ __html: item.image }}
                      />
                      <Text tt="capitalize">{item.name}</Text>
                    </Tabs.Tab>
                  ))}

                  {/* <Tabs.Tab value="shop">
                    <div className={classes.serviceIcon}>
                      <svg
                        className={classes.serviceIcon}
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M30.3076 8.26571C29.6181 7.31371 28.5484 6.76778 27.373 6.76778H8.85208C7.93749 6.76778 7.10107 7.10864 6.46281 7.66964V5.70732C6.46281 4.76585 5.69697 4 4.75572 4H2.61694C2.27629 4 2 4.2763 2 4.61694C2 4.95759 2.2763 5.23388 2.61694 5.23388H4.75572C5.01653 5.23388 5.22892 5.44628 5.22892 5.70709V19.8319C5.22892 21.8295 6.85427 23.4549 8.85212 23.4549H24.2732C25.8426 23.4549 27.2258 22.4531 27.7156 20.9621L30.8156 11.521C31.1821 10.4041 30.997 9.21774 30.3075 8.26553L30.3076 8.26571ZM29.6429 11.1363L26.5429 20.5775C26.2201 21.5605 25.3079 22.2211 24.273 22.2211H8.85197C7.53457 22.2211 6.4627 21.1495 6.4627 19.8321L6.46292 10.3915C6.46292 9.07408 7.53479 8.00221 8.85218 8.00221H27.3737C28.1488 8.00221 28.8542 8.36223 29.3087 8.99018C29.7634 9.61792 29.8854 10.4001 29.6433 11.1367L29.6429 11.1363Z"
                          fill="#98A2B3"
                        />
                        <path
                          d="M7.97232 24.9643C7.48447 24.9643 7.00716 25.1616 6.66285 25.5059C6.31854 25.8502 6.12122 26.3266 6.12122 26.8121C6.12122 27.3 6.31854 27.7771 6.66285 28.1214C7.0091 28.4676 7.48616 28.666 7.9721 28.666C8.46251 28.666 8.94089 28.4665 9.27853 28.1244C9.62456 27.7827 9.82296 27.3043 9.82296 26.8122C9.82296 26.3217 9.62434 25.8445 9.28413 25.5085C8.94284 25.1627 8.46468 24.9643 7.97235 24.9643L7.97232 24.9643ZM8.40614 27.2517C8.18084 27.4801 7.76898 27.4824 7.53546 27.2489C7.42076 27.1345 7.35513 26.975 7.35513 26.8121C7.35513 26.6533 7.4227 26.4913 7.53525 26.3783C7.64994 26.2638 7.80897 26.1982 7.97208 26.1982C8.13649 26.1982 8.29466 26.2629 8.41172 26.3813C8.52448 26.4925 8.58904 26.6494 8.58904 26.8121C8.58926 26.9767 8.5247 27.1349 8.40613 27.2517L8.40614 27.2517Z"
                          fill="#98A2B3"
                        />
                        <path
                          d="M21.2162 24.9643C20.7284 24.9643 20.2511 25.1616 19.907 25.5059C19.5627 25.85 19.3654 26.3262 19.3654 26.8121C19.3654 27.3 19.5629 27.7775 19.907 28.1214C20.2532 28.4676 20.7303 28.666 21.2162 28.666C21.7022 28.666 22.1792 28.4674 22.5255 28.1214C22.8698 27.7771 23.0673 27.2998 23.0673 26.8121C23.0673 26.3262 22.8698 25.8502 22.5255 25.5059C22.1814 25.1616 21.7041 24.9643 21.2162 24.9643ZM21.6531 27.249C21.4198 27.4816 21.0122 27.4812 20.7796 27.249C20.6652 27.1345 20.5995 26.975 20.5995 26.8121C20.5995 26.6533 20.6669 26.4913 20.7796 26.3783C20.8943 26.2638 21.0534 26.1982 21.2165 26.1982C21.3794 26.1982 21.5386 26.2638 21.6531 26.3783C21.7659 26.4913 21.8334 26.6533 21.8334 26.8121C21.8334 26.9753 21.7676 27.1343 21.6531 27.249H21.6531Z"
                          fill="#98A2B3"
                        />
                        <path
                          d="M25.912 10.5222H10.3152C9.9746 10.5222 9.6983 10.7985 9.6983 11.1391C9.6983 11.4798 9.97461 11.7561 10.3152 11.7561H25.912C26.2527 11.7561 26.529 11.4798 26.529 11.1391C26.529 10.7985 26.2529 10.5222 25.912 10.5222Z"
                          fill="#98A2B3"
                        />
                      </svg>
                    </div>
                    <Text>Shop</Text>{" "}
                  </Tabs.Tab> */}
                  <Tabs.Tab value="blog">
                    <div className={classes.serviceIcon}>
                      <svg
                        className={classes.serviceIcon}
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{
                          width: theme.fn.smallerThan("xs") ? "22px" : "38px",
                          height: theme.fn.smallerThan("xs") ? "22px" : "38px",
                        }}
                      >
                        <path
                          d="M31 30.7003V2H2V30.7003H31ZM2.78682 29.951V8.74425H30.2506V29.951H2.78682ZM30.2506 2.74942V7.99477L2.74935 7.99503V2.74968L30.2506 2.74942Z"
                          fill="#98A2B3"
                        />
                        <path
                          d="M7.32043 17.6615H12.1913V12.7906H7.32043V17.6615ZM8.06972 13.5399H11.4417V16.9119H8.06972V13.5399Z"
                          fill="#98A2B3"
                        />
                        <path
                          d="M16.6873 12.7906H22.6823V13.5399H16.6873V12.7906Z"
                          fill="#98A2B3"
                        />
                        <path
                          d="M16.6873 16.5374H25.6796V17.2867H16.6873V16.5374Z"
                          fill="#98A2B3"
                        />
                        <path
                          d="M7.32043 25.9044H12.1913V21.0335H7.32043V25.9044ZM8.06972 21.7828H11.4417V25.1548H8.06972V21.7828Z"
                          fill="#98A2B3"
                        />
                        <path
                          d="M16.6873 21.0335H22.6823V21.7828H16.6873V21.0335Z"
                          fill="#98A2B3"
                        />
                        <path
                          d="M16.6873 24.7803H25.6796V25.5296H16.6873V24.7803Z"
                          fill="#98A2B3"
                        />
                      </svg>
                    </div>
                    <Text>Blog</Text>{" "}
                  </Tabs.Tab>
                  {/* <Tabs.Tab value="events">
                    <div className={classes.serviceIcon}>
                      <svg
                        className={classes.serviceIcon}
                        viewBox="0 0 42 42"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{
                          width: theme.fn.smallerThan("xs") ? "32px" : "38px",
                          height: theme.fn.smallerThan("xs") ? "32px" : "38px",
                        }}
                      >
                        <path
                          d="M12.5363 33.696H29.4642C30.5867 33.696 31.6631 33.2501 32.4566 32.4564C33.2503 31.6629 33.6962 30.5865 33.6962 29.464V14.6523C33.6962 13.5298 33.2503 12.4534 32.4566 11.6597C31.6631 10.866 30.5867 10.4203 29.4642 10.4203H28.4063V9.3622C28.4063 8.98434 28.2045 8.63502 27.8773 8.44591C27.5498 8.25698 27.1466 8.25698 26.8192 8.44591C26.4919 8.63506 26.2903 8.98435 26.2903 9.3622V10.4201L22.0583 10.4203V9.3622C22.0583 8.98433 21.8567 8.63502 21.5293 8.4459C21.202 8.25697 20.7986 8.25697 20.4714 8.4459C20.1439 8.63505 19.9423 8.98434 19.9423 9.3622V10.4201L15.7103 10.4203V9.36219C15.7103 8.98433 15.5087 8.63501 15.1813 8.4459C14.854 8.25697 14.4506 8.25697 14.1234 8.4459C13.7959 8.63505 13.5943 8.98434 13.5943 9.36219V10.4201L12.5364 10.4203C11.414 10.4203 10.3375 10.866 9.54383 11.6597C8.75014 12.4534 8.30444 13.5298 8.30444 14.6523V29.464C8.30444 30.5865 8.75016 31.6629 9.54383 32.4564C10.3375 33.2501 11.414 33.696 12.5364 33.696L12.5363 33.696ZM29.4642 31.58H12.5363C11.9749 31.58 11.4367 31.357 11.0399 30.9601C10.6432 30.5633 10.4203 30.0251 10.4203 29.464V18.8841H31.58V29.464C31.58 30.0251 31.357 30.5633 30.9602 30.9601C30.5633 31.357 30.0251 31.58 29.464 31.58H29.4642ZM12.5363 12.5359H13.5942V13.5938C13.5942 13.9718 13.7958 14.3211 14.1232 14.5101C14.4505 14.699 14.8539 14.699 15.1811 14.5101C15.5086 14.3211 15.7102 13.9718 15.7102 13.5938V12.5359H19.9422V13.5938C19.9422 13.9718 20.1438 14.3211 20.4712 14.5101C20.7985 14.699 21.2019 14.699 21.5291 14.5101C21.8566 14.3211 22.0582 13.9718 22.0582 13.5938V12.5359H26.2902V13.5938C26.2902 13.9718 26.4918 14.3211 26.819 14.5101C27.1465 14.699 27.5497 14.699 27.8771 14.5101C28.2044 14.3211 28.4062 13.9718 28.4062 13.5938V12.5359H29.464C30.0252 12.5359 30.5634 12.7586 30.9602 13.1555C31.3571 13.5523 31.5801 14.0906 31.5801 14.6519V16.7679L10.4203 16.7677V14.6519C10.4203 14.0906 10.6433 13.5523 11.04 13.1555C11.4368 12.7587 11.975 12.5359 12.5364 12.5359L12.5363 12.5359Z"
                          fill="#98A2B3"
                        />
                        <path
                          d="M23.4255 22.3672L19.942 25.8507L18.5746 24.4832C18.3071 24.2158 17.9174 24.1115 17.552 24.2092C17.1868 24.3072 16.9014 24.5926 16.8035 24.9577C16.7055 25.3232 16.81 25.7129 17.0775 25.9803L19.1935 28.0964C19.392 28.2951 19.6613 28.4066 19.942 28.4066C20.2228 28.4066 20.492 28.2951 20.6905 28.0964L24.9225 23.8643C25.19 23.5971 25.2943 23.2072 25.1966 22.8418C25.0986 22.4766 24.8132 22.1912 24.448 22.0933C24.0826 21.9955 23.6929 22.1 23.4255 22.3673L23.4255 22.3672Z"
                          fill="#98A2B3"
                        />
                      </svg>
                    </div>
                    <Text>Events</Text>{" "}
                  </Tabs.Tab> */}
                </>
              )}
            </Tabs.List>
          </Tabs>
        </Center>
      </ScrollArea>
    </Center>
  );
}
