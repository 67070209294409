import { useEffect } from "react";

import { useRef } from "react";
import Autoplay from "embla-carousel-autoplay";
import { Carousel } from "@mantine/carousel";

import { useSelector, useDispatch } from "react-redux";
import { selectAllBanners } from "../Banners/bannersSlice";
import { getBanners } from "../../api/bannersApi";
import { AppDispatch } from "../../app/store";
import FullLoader from "../../components/Loaders/FullLoader";
import {
  AspectRatio,
  Button,
  Grid,
  Group,
  Title,
  Text,
  createStyles,
  Container,
} from "@mantine/core";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";

const useStyles = createStyles((theme) => ({
  content: {
    [theme.fn.smallerThan("md")]: {
      maxWidth: "100%",
      marginRight: 0,
    },
  },

  title: {
    fontFamily: "Poppins !important",
    // fontSize: 74,
    fontWeight: 600,

    [theme.fn.smallerThan("xs")]: {
      fontSize: 28,
    },
  },

  control: {
    fontFamily: "Poppins",
    [theme.fn.smallerThan("xs")]: {
      flex: 1,
      margin: "8px 0",
    },
  },

  image: {
    flex: 1,

    [theme.fn.smallerThan("md")]: {
      display: "none",
    },
  },

  highlight: {
    position: "relative",
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.fn.rgba(theme.colors[theme.primaryColor][6], 0.55)
        : theme.colors[theme.primaryColor][0],
    borderRadius: theme.radius.sm,
    padding: "4px 12px",
  },
}));

let initialValues = {
  title: "",
  images: new FormData(),
  subtitle: "",
  link: "",
  background: "",
  buttons: [],
  btnText: "",
  btnLink: "",
  btnBackground: "",
};

const columns = ["title", "link", "createdAt"];
const bannersFields = [
  "title",
  "subtitle",
  "link",
  "images",
  "background",
  "buttons",
];

export function Events() {
  const { classes } = useStyles();
  const banners = useSelector(selectAllBanners);

  const dispatch = useDispatch<AppDispatch>();
  const autoplay = useRef(Autoplay({ delay: 2000 }));

  const bannersLoading = useSelector((state: any) => state.banners.isLoading);

  useEffect(() => {
    dispatch(getBanners(""));
  }, [dispatch]);

  return (
    <>
      {banners?.length > 0 ? (
        <Container size="xl">
          <Carousel
            // sx={{ maxWidth: 320 }}
            mx="auto"
            loop
            withIndicators
            height={500}
            plugins={[autoplay.current]}
            onMouseEnter={autoplay.current.stop}
            onMouseLeave={autoplay.current.reset}
            styles={{
              indicator: {
                width: 12,
                height: 6,
                borderRadius: 24,
                background: "rgba(244, 129, 109, 0.3)",
                transition: "width 500ms ease",

                "&[data-active]": {
                  width: 40,
                  background: "#F4816D",
                },
              },
            }}
          >
            {banners?.map((banner: any, index: any) => (
              <Carousel.Slide key={index}>
                <div
                  style={{
                    // background: banner?.background,
                    height: "100%",
                    // backgroundPosition: "top center",
                    // backgroundSize: "cover",
                  }}
                >
                  <Grid
                    columns={12}
                    p={0}
                    m={0}
                    justify={"space-evenly"}
                    align="stretch"
                    style={{ height: "100%" }}
                  >
                    <Grid.Col md={0}>
                      <AspectRatio
                        ratio={5 / 4}
                        sx={{ maxWidth: "90%" }}
                        ml="auto"
                        my={0}
                      >
                        {/* <LazyLoad height={235}  placeholder={<Skeleton height={200} width="100%" />}>
                                     <Image src={trainer} alt='trainer-showcase' width={'100%'}/>
                                     </LazyLoad> */}
                        <LazyLoadImage
                          alt="demonstration2"
                          effect="blur"
                          src={banner?.image?.lg}
                        />
                      </AspectRatio>
                    </Grid.Col>
                    <Grid.Col md={5} px={24}>
                      <Text
                        className={classes.title + " mainTextColor"}
                        sx={(theme) => ({
                          "@media (max-width: 550px)": {
                            textAlign: "center",
                            fontSize: "5vw!important",
                          },
                          "@media (min-width: 550px)": {
                            fontSize: "4vw!important",
                            textAlign: "center",
                          },
                          "@media (min-width: 992px)": {
                            fontSize: "2vw!important",
                            textAlign: "left",
                          },
                          "@media (min-width: 1230px)": {
                            fontSize: "2.5vw!important",
                          },
                        })}
                      >
                        {banner?.title}
                      </Text>
                      <Text
                        color=""
                        align="left"
                        mt="md"
                        style={{ opacity: 0, height: 0 }}
                      >
                        A place where you can find and book great personal
                        trainers, to save time, build consintency and get
                        personalized sessions. Trainers be able to make money
                        and build brand online
                      </Text>
                      <Text
                        color=""
                        align="left"
                        mt="md"
                        sx={(theme) => ({
                          "@media (max-width: 992px)": {
                            textAlign: "center",
                          },
                          "@media (min-width: 992px)": {
                            textAlign: "left",
                          },
                        })}
                      >
                        A place where you can find and book great personal
                        trainers, to save time, build consintency and get
                        personalized sessions. Trainers are able to make money
                        and build their brand online.
                      </Text>

                      <Group
                        my={30}
                        position={
                          window.self.innerWidth < 999 ? "center" : "left"
                        }
                      >
                        {banner.button ? (
                          <Link to="/trainers" className={classes.control}>
                            <Button
                              radius="xl"
                              size="xl"
                              fullWidth
                              className={classes.control}
                            >
                              {" "}
                              text here
                            </Button>
                          </Link>
                        ) : null}
                      </Group>
                    </Grid.Col>
                    <Grid.Col
                      xs={0}
                      md={6}
                      sx={(theme) => ({
                        background:
                          "url(" + banner?.image?.lg + ") top left no-repeat",
                        backgroundSize: "contain",
                        "@media (max-width: 992px)": {
                          display: "none",
                        },
                      })}
                    ></Grid.Col>
                  </Grid>
                </div>
              </Carousel.Slide>
            ))}
          </Carousel>
        </Container>
      ) : (
        <FullLoader />
      )}
    </>
  );
}
