import { useEffect, useState } from "react";
import {
  createStyles,
  Text,
  Group,
  Burger,
  Image,
  UnstyledButton,
  ColorScheme,
  Avatar,
  Menu,
  Card,
  Header,
  ActionIcon,
} from "@mantine/core";
import {
  useHotkeys,
  useLocalStorage,
  useMediaQuery,
  useToggle,
} from "@mantine/hooks";
import Logo from "../../assets/logo/logo1.png";
import shortLogo from "../../assets/logo/logo.png";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { CalendarEdit, Logout, Moon, Profile, Sun1 } from "iconsax-react";
import { IconSearch } from "@tabler/icons";
import { openSpotlight } from "@mantine/spotlight";
import { LandingNav } from "../../features/Landing/LandingNav";
import { openModal, closeAllModals, closeModal } from "@mantine/modals";
import { Login } from "../../features/auth/Login";

const headerHeight = 180;
const useStyles = createStyles((theme) => ({
  control: {
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[8]
        : theme.colors.gray[0],
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 1000,
    paddingLeft: theme.spacing.sm,
    paddingRight: 4,
    width: 136,
    height: 36,
  },

  iconWrapper: {
    height: 28,
    width: 28,
    borderRadius: 28,
    color: theme.colorScheme === "dark" ? theme.black : theme.colors.blue[2],
  },

  value: {
    lineHeight: 1,
  },
  logo: {
    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },

  header: {
    borderBottom: 0,
    paddingTop: 24,
    top: 0,
    boxShadow: "0 3.5px 8px rgba(0, 0, 0, 0.1)",
    position: "absolute",
    width: "100%",
    zIndex: 2,
    background:
      theme.colorScheme === "dark"
        ? "rgba(0, 0, 0, 1)"
        : "rgba(255, 255, 255, 1)",
  },
  inner: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  links: {
    [theme.fn.smallerThan("md")]: {
      display: "none",
    },
  },

  burger: {
    [theme.fn.largerThan("md")]: {
      display: "none",
    },
  },

  link: {
    padding: "8px 12px",
    borderRadius: theme.radius.lg,
    textDecoration: "none !important",
    fontSize: theme.fontSizes.md,
    fontWeight: 500,
    transition: "all 200ms ease",

    "&:hover": {
      transform: "scale(1.01)",
      backgroundColor: "rgba(244, 129, 109,.15)",
    },
    [theme.fn.smallerThan("xs")]: {
      margin: "8px auto",
      padding: "16px 24px",
    },
  },

  linkActive: {
    "&, &:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.fn.rgba(theme.colors[theme.primaryColor][9], 0.25)
          : theme.colors[theme.primaryColor][0],
      color:
        theme.colors[theme.primaryColor][theme.colorScheme === "dark" ? 3 : 7],
    },
  },

  userMenu: {
    [theme.fn.smallerThan("xs")]: {
      // display: 'none',
    },
  },

  user: {
    color: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.black,
    padding: `${theme.spacing.xs}px ${theme.spacing.sm}px`,
    borderRadius: theme.radius.sm,
    transition: "background-color 100ms ease",

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.white,
    },
  },

  userActive: {
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.white,
  },
}));

export interface HeaderSimpleProps {
  links: { link: string; label: string }[];
}

export function HeaderSimple({
  openSideBar,
  onOpenSideBar,
  closeSideBar,
}: {
  openSideBar: boolean;
  onOpenSideBar(): void;
  closeSideBar(): void;
}) {
  const [colorScheme, setColorScheme] = useLocalStorage<ColorScheme>({
    key: "mantine-color-scheme",
    defaultValue: "light",
    getInitialValueInEffect: true,
  });

  const isMobile = useMediaQuery("(min-width: 955px)");
  const toggleColorScheme = (value?: ColorScheme) =>
    setColorScheme(value || (colorScheme === "dark" ? "light" : "dark"));

  useHotkeys([["mod+J", () => toggleColorScheme()]]);
  const Icon = colorScheme === "dark" ? Sun1 : Moon;

  const [currencyData, setCurrencyData] = useState(["USD"]);
  const [openLogin, setOpenLogin] = useState(false);
  const [opened, toggleOpened] = useToggle([false, true]);
  const { classes, theme, cx } = useStyles();
  const location = useLocation();
  const auth = JSON.parse(localStorage.getItem("auth") || "null");

  const navigate = useNavigate();

  useEffect(() => {
    const fetchMyLocation = async () => {
      var cur = ["USD"];
      setCurrencyData(cur);
    };

    fetchMyLocation().catch(console.error);
  }, []);

  return (
    <Header height={"0"}>
      <div className={classes.header}>
        <Group position="apart" px={"5%"} pb={"xl"}>
          <Link to={"/"} className={classes.logo}>
            <Image src={Logo} alt="wearefitabo.com" mx={12} width={150} />
          </Link>

          <Card radius={"xl"} withBorder shadow={"md"} px={12} py={8}>
            <Group noWrap spacing={12}>
              <ActionIcon
                size={28}
                radius="xl"
                variant="light"
                p={4}
                color="primary"
                onClick={() => openSpotlight()}
              >
                <IconSearch size={24} />
              </ActionIcon>
              <Group spacing={0} onClick={() => openSpotlight()}>
                <Text pr={8}>Looking for? </Text>
                <Text
                  align="left"
                  size="xs"
                  color={"dimmed"}
                  style={{ verticalAlign: "center" }}
                >
                  Fitness . Beauty . Sports . Wellness{" "}
                </Text>
              </Group>
              <ActionIcon
                variant="light"
                radius="xl"
                p={4}
                size={28}
                color={colorScheme === "light" ? "dark" : "#F4816D"}
                onClick={() => {
                  toggleColorScheme();
                  toggleOpened(false);
                }}
              >
                <Icon
                  size={24}
                  color={colorScheme === "light" ? "black" : "#F4816D"}
                />
              </ActionIcon>
            </Group>
          </Card>
          {auth?.role?.name === "admin" ? (
            <UnstyledButton onClick={() => navigate("/admin")}>
              <Group spacing={16}>
                <div>
                  <Group position="right">
                    <Text size="sm" color="dimmed" tt="capitalize" fw={600}>
                      {auth?.firstname} {auth?.lastname}
                    </Text>
                  </Group>
                  <Group position="right">
                    <Text size="sm" tt="capitalize" className="mainTextColor">
                      {auth?.role?.name}
                    </Text>
                  </Group>
                </div>
                <Avatar
                  size={36}
                  variant="filled"
                  src={auth?.profileImage?.sm || ""}
                  radius="xl"
                />
              </Group>
            </UnstyledButton>
          ) : (
            <Group className={classes.logo}>
              <Menu
                shadow="md"
                radius={"lg"}
                width={250}
                opened={openSideBar}
                // onChange={closeSideBar}
              >
                <Menu.Target>
                  <Card
                    withBorder
                    radius={"xl"}
                    px={8}
                    py={4}
                    onClick={onOpenSideBar}
                  >
                    <Group spacing={4}>
                      <Avatar
                        size="sm"
                        src={auth?.profileImage?.sm || ""}
                        radius="xl"
                      />
                      <Burger
                        aria-label="Menu"
                        opened={openSideBar}
                        size="sm"
                      />
                    </Group>
                  </Card>
                </Menu.Target>
                {auth?.role?.name !== "admin" && (
                  <Menu.Dropdown>
                    <Menu.Label>
                      {" "}
                      <Text
                        align="center"
                        py={"xl"}
                        size={"xl"}
                        color={"#F4816D"}
                      >
                        We're Fitabo
                      </Text>
                    </Menu.Label>
                    <Menu.Item
                      component={Link}
                      className={classes.link}
                      to="/"
                      icon={<Avatar src={shortLogo} size={20} radius="xl" />}
                      onClick={closeSideBar}
                    >
                      Home
                    </Menu.Item>
                    <Menu.Divider />
                    {auth?.isAuthenticated ? (
                      <>
                        <Menu.Item
                          component={Link}
                          className={classes.link}
                          to="/bookings"
                          icon={<CalendarEdit size={20} color={"#F4816D"} />}
                          onClick={closeSideBar}
                        >
                          My Bookings
                        </Menu.Item>
                        <Menu.Divider />
                        {/* {auth?.accountType === "serviceProvider" && (
                          <>
                            <Menu.Item
                              component={Link}
                              className={classes.link}
                              to="/myservices"
                              icon={<Category2 size={20} color={"#F4816D"} />}
                              onClick={closeSideBar}
                            >
                              My Services
                            </Menu.Item>

                            <Menu.Divider />
                          </>
                        )} */}
                        <Menu.Item
                          icon={<Profile size={20} color={"#F4816D"} />}
                          component={Link}
                          className={classes.link}
                          to="/profile"
                          onClick={closeSideBar}
                        >
                          Profile
                        </Menu.Item>
                        <Menu.Divider />
                        <Menu.Item
                          icon={<Logout size={20} color={"#F4816D"} />}
                          component={Link}
                          className={classes.link}
                          to="/sign-out"
                          onClick={closeSideBar}
                        >
                          Logout
                        </Menu.Item>
                      </>
                    ) : (
                      <Menu.Item
                        // fullWidth
                        // radius="xl"
                        p={12}
                        mt={24}
                        color="white"
                        className={" mainBgColor"}
                        onClick={() => {
                          closeSideBar();
                          setOpenLogin(
                            location?.pathname === "/sign-in" ? false : true
                          );
                        }}
                      >
                        <Text align="center" weight={500}>
                          Sign in
                        </Text>
                      </Menu.Item>
                    )}

                    <Menu.Label />
                  </Menu.Dropdown>
                )}
              </Menu>
            </Group>
          )}
        </Group>
        <LandingNav />
        {openLogin && (
          <Login
            goTo={location.pathname}
            openLogin={openLogin}
            onCloseLogin={() => setOpenLogin(false)}
          />
        )}
      </div>
    </Header>
  );
}
