import { appSlice } from "../../app/appSlice";

export const imageUploadSlice = appSlice.injectEndpoints({
  endpoints: (builder) => ({
    uploadImage: builder.mutation({
      query: (body) => ({
        url: "/images",
        method: "POST",
        body: body,
      }),
    }),
    deleteImage: builder.mutation({
      query: ({ _id, body }) => ({
        url: `/images`,
        method: "DELETE",
        body: body,
      }),
    }),
  }),
});

export const { useUploadImageMutation, useDeleteImageMutation } =
  imageUploadSlice;
