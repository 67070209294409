import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const appSlice = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl:
      process.env.NODE_ENV === "development"
        ? "http://localhost:2500"
        : "https://www.wearefitabo.com/api",
        //"http://45.140.185.227/api",
    prepareHeaders: (headers) => {
      const token =
        JSON.parse(localStorage.getItem("auth") || "null")?.token || "null";

      // If we have a token set in state, let's assume that we should be passing it.
      headers.set("Content-type", "application/json");
      if (token) {
        headers.set("authorization", token);
      }

      return headers;
    },
  }),
  tagTypes: [
    "Auth",
    "Banners",
    "Blogs",
    "BlogCategories",
    "BlogsComments",
    "BlogsLikes",
    "Bookings",
    "Categories",
    "Faqs",
    "Location",
    "Permissions",
    "Providers",
    "Profile",
    "Policies",
    "Reviews",
    "Roles",
    "Search",
    "Services",
    "Users",
  ],
  endpoints: (builder) => ({}),
});
