import { api } from "./apiClient";

export async function subscribePushNotitication(
  token: String,
  user: String
): Promise<any> {
  try {
    const response = await api().post("/pushSubscriptions", { token, user });
    return response;
  } catch (error) {
    throw error;
  }
}
